import Header from '@/components/Header';
import { Stack, Typography } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { filter, reduce, some } from 'lodash';
import { Routes } from '@/constants/routes';
import TableGenerator, { RowCell } from '@/components/ui/TableGenerator';
import {
  CashGrantsStartingThisTear,
  DownstreamCashFunding,
  RealEstatePromotion,
  TaxIncentivesRefunds,
  TotalCashGrants,
} from '@/constants/tables';
import useCustomerStore from '@/store/customer';
import { euro } from '@/utils/math';
import currency from 'currency.js';

const FundingView = () => {
  const { t } = useTranslation();
  const matches = useMatches();
  const { funding, getFunding } = useCustomerStore();

  const barValues = useMemo(() => {
    const initial: { rows: RowCell[][]; sum: currency } = {
      rows: [],
      sum: euro(0),
    };

    if (!funding) {
      return initial;
    }

    const barItems = filter(funding, { type: 'bar' });

    return reduce(
      barItems,
      (acc, item) => {
        const rows: RowCell[][] = [
          ...acc.rows,
          [
            {
              value: item.description,
            },
            {
              value: item.purpose,
            },
            {
              value: item.sum,
              transform: (value) => euro(value).format(),
            },
            {
              value: item.comment,
            },
          ],
        ];
        const sum = acc.sum.add(item.sum);

        return {
          rows,
          sum,
        };
      },
      initial,
    );
  }, [funding]);

  const downstreamValues = useMemo(() => {
    const initial: { rows: RowCell[][]; sum: currency } = {
      rows: [],
      sum: euro(0),
    };

    if (!funding) {
      return initial;
    }

    const items = filter(funding, { type: 'nachgelagert' });

    return reduce(
      items,
      (acc, item) => {
        const rows: RowCell[][] = [
          ...acc.rows,
          [
            {
              value: item.description,
            },
            {
              value: item.purpose,
            },
            {
              value: item.sum,
              transform: (value) => euro(value).format(),
            },
            {
              value: item.comment,
            },
          ],
        ];
        const sum = acc.sum.add(item.sum);

        return {
          rows,
          sum,
        };
      },
      initial,
    );
  }, [funding]);

  const promotionValues = useMemo(() => {
    if (!funding) {
      return [];
    }

    const items = filter(funding, { type: 'immobilien' });

    return reduce(
      items,
      (acc, item) => {
        return [
          ...acc,
          [
            {
              value: item.description,
            },
            {
              value: item.purpose,
            },
            {
              value: item.sum,
              transform: (value) => euro(value).format(),
            },
          ],
        ];
      },
      [] as RowCell[][],
    );
  }, [funding]);

  const taxValues = useMemo(() => {
    if (!funding) {
      return [];
    }

    const items = filter(funding, { type: 'steuer' });

    return reduce(
      items,
      (acc, item) => {
        return [
          ...acc,
          [
            {
              value: item.description,
            },
            {
              value: item.purpose,
            },
            {
              value: item.sum,
              transform: (value) => euro(value).format(),
            },
          ],
        ];
      },
      [] as RowCell[][],
    );
  }, [funding]);

  useEffect(() => {
    if (!funding) {
      console.log('get funding');
      getFunding().catch(console.error);
    }
  }, [funding, getFunding]);

  const isNewCustomer = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.NewCustomer);
    });
  }, [matches]);

  return (
    <Stack flex={1}>
      <Header
        title={t(
          !isNewCustomer
            ? 'customer:customerDetails.title'
            : 'customer:newCustomer.title',
        )}
        subtitle={t('customer:funding.title')}
      />
      <Stack px={6} pb={6} gap={6} overflow="auto">
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:funding.section1.title')}
            </Typography>
            <TableGenerator
              head={CashGrantsStartingThisTear}
              values={barValues.rows}
            />
          </Stack>
        </Section>
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:funding.section2.title')}
            </Typography>
            <TableGenerator
              head={DownstreamCashFunding}
              values={downstreamValues.rows}
            />
          </Stack>
        </Section>
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:funding.section3.title')}
            </Typography>
            <TableGenerator
              head={TotalCashGrants}
              values={[
                [
                  {
                    value: barValues.sum.format(),
                  },
                  {
                    value: downstreamValues.sum.format(),
                  },
                  {
                    value: barValues.sum.add(downstreamValues.sum).format(),
                  },
                ],
              ]}
            />
          </Stack>
        </Section>
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:funding.section4.title')}
            </Typography>
            <TableGenerator
              head={RealEstatePromotion}
              values={promotionValues}
            />
          </Stack>
        </Section>
        <Section>
          <Stack gap="19px">
            <Typography variant="subtitle2">
              {t('customer:funding.section5.title')}
            </Typography>
            <TableGenerator head={TaxIncentivesRefunds} values={taxValues} />
          </Stack>
        </Section>
      </Stack>
    </Stack>
  );
};

export default FundingView;
