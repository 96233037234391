import { SingleSelectItem } from '@/components/ui/SingleSelect';

export const Tariffs: SingleSelectItem[] = [
  {
    value: 'axa',
    label: 'AXA (ETF)',
  },
  {
    value: 'bpt',
    label: 'Bausparvertrag',
  },
  {
    value: 'klv',
    label: 'Indexpolice/KLV',
  },
];

export const TariffsRange = {
  axa: {
    start: 6,
    end: 12,
    step: 1,
  },
  bpt: {
    start: 0.1,
    end: 3,
    step: 0.25,
  },
  klv: {
    start: 0,
    end: 3.5,
    step: 0.5,
  },
};
