import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { findIndex, some } from 'lodash';
import { Formik, FormikProps } from 'formik';

import TabsView from '@/components/ui/TabsView';
import Tabs from '@/components/ui/Tabs';
import NewCustomerStepper, {
  NewCustomerStepperRef,
} from '@/components/NewCustomerStepper';
import ChildrenStepper from '@/components/ChildrenStepper';

import { NewCustomerTab, NewCustomerTabItems } from '@/constants/tabs';
import { CustomerForm } from '@/types';
import { useMatches, useNavigate } from 'react-router-dom';
import useCustomerStore from '@/store/customer';
import { getPath } from '@/utils';
import { Routes } from '@/constants/routes';
import { updateCustomerValidationSchema } from '@/constants/validation';
import { Stack } from '@mui/material';
import Header from '@/components/Header';
import { useTranslation } from 'react-i18next';

const CustomerDetailsView = () => {
  const formRef = useRef<FormikProps<CustomerForm>>(null);
  const [formTouched, setFormTouched] = useState(false);
  const { t } = useTranslation();

  const matches = useMatches();

  const isNewCustomer = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.NewCustomer);
    });
  }, [matches]);

  const {
    customerId,
    customer,
    partner,
    error,
    updateCustomer,
    fetchCustomer,
    createCustomer,
    clearState,
  } = useCustomerStore();
  const navigate = useNavigate();

  const stepperRef = useRef<
    Record<NewCustomerTab, MutableRefObject<NewCustomerStepperRef | null>>
  >({
    client: { current: null },
    partner: { current: null },
    children: { current: null },
  });

  const [activeTab, setActiveTab] = useState(NewCustomerTab.Client);

  useEffect(() => {
    if (
      activeTab === NewCustomerTab.Partner &&
      !customer?.partner?.id &&
      customerId
    ) {
      createCustomer('partner', { partner_id: customerId }).catch(
        console.error,
      );
    }

    if (customer?.partner?.id) {
      fetchCustomer('partner', customer.partner.id).catch(console.error);
    }
  }, [
    activeTab,
    createCustomer,
    customer?.partner?.id,
    customerId,
    fetchCustomer,
  ]);

  useEffect(() => {
    if (customer || partner) {
      formRef.current?.setValues({
        customer,
        partner,
      });
    }
  }, [customer, partner]);

  const activeTabIndex = useMemo(() => {
    return findIndex(NewCustomerTabItems, { id: activeTab });
  }, [activeTab]);

  const handleSelectActiveTab = async (tab: string) => {
    await formRef.current?.submitForm();
    setActiveTab(tab as NewCustomerTab);
  };

  const handleSave = async () => {
    await formRef.current?.submitForm();
    navigate(getPath('..', Routes.Finance));
  };

  const handleStepperEnd = () => {
    const nextTabIndex = activeTabIndex + 1;

    if (nextTabIndex < NewCustomerTabItems.length) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleStepperStart = () => {
    const nextTabIndex = activeTabIndex - 1;

    if (nextTabIndex >= 0) {
      setActiveTab(NewCustomerTabItems[nextTabIndex].id as NewCustomerTab);
    }
  };

  const handleSubmit = async (data: CustomerForm) => {
    setFormTouched(true);

    if (!customerId && data.customer) {
      await createCustomer('customer', data.customer);
      return;
    }

    const updateKey =
      activeTab === NewCustomerTab.Partner ? 'partner' : 'customer';

    const updateId =
      activeTab === NewCustomerTab.Partner ? customer?.partner?.id : customerId;

    const updateData = data[updateKey];

    if (!updateId || !updateData) {
      return;
    }

    updateCustomer(updateKey, updateId, updateData).catch(console.error);
  };

  const handleExitFromCustomer = () => {
    clearState();
    navigate('/home/customers');
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        customer,
        partner,
      }}
      validationSchema={updateCustomerValidationSchema}
      onSubmit={handleSubmit}
    >
      <Stack flex={1} pb={7} overflow="hidden">
        <Header
          title={t(
            !isNewCustomer
              ? 'customer:customerDetails.title'
              : 'customer:newCustomer.title',
          )}
        />
        <Stack flex={1} px={6} gap={3} overflow="hidden">
          <Tabs
            activeTab={activeTab}
            items={NewCustomerTabItems}
            onSelect={handleSelectActiveTab}
          />
          <TabsView
            activeTabIndex={activeTabIndex}
            tabs={[
              <NewCustomerStepper
                ref={stepperRef.current.client}
                onStepperEnd={handleStepperEnd}
                isCustomerDetails={!isNewCustomer}
                onStepperStart={handleStepperStart}
                formType="customer"
                hasError={!!error}
                onCancel={handleExitFromCustomer}
                formTouched={formTouched}
              />,
              <NewCustomerStepper
                ref={stepperRef.current.partner}
                onStepperEnd={handleStepperEnd}
                onStepperStart={handleStepperStart}
                isCustomerDetails={!isNewCustomer}
                hasError={!!error}
                formType="partner"
                onCancel={handleExitFromCustomer}
                formTouched={formTouched}
              />,
              <ChildrenStepper
                ref={stepperRef.current.children}
                onStepperStart={handleStepperStart}
                hasError={!!error}
                onFinishForm={handleSave}
                onCancel={handleExitFromCustomer}
                formTouched={formTouched}
              />,
            ]}
          />
        </Stack>
      </Stack>
    </Formik>
  );
};

export default CustomerDetailsView;
