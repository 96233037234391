import { SXStyleSheet } from '@/utils';
import { outlinedInputClasses } from '@mui/material';

const style = SXStyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: 500,
  },
  input: {
    [`&.${outlinedInputClasses.root}`]: {
      height: '58px',
    },
  },
  inputInForm: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      fontWeight: 500,
      width: 274,
      maxWidth: 274,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
});

export default style;
