import { create } from 'zustand';

import { UsersState, UserStore } from './types';
import api from '@/services/api';
import { ErrorResponse } from '@/utils';
import { DEFAULT_USERS_PER_PAGE } from '@/constants/env';

const initialState: UsersState = {
  users: null,
  pagination: null,
  loading: false,
  fetchError: null,
  createError: null,
};

const useUsersStore = create<UserStore>((set, getState) => ({
  ...initialState,
  fetchUsers: async () => {
    set({ loading: true });
    try {
      const { data, meta } = await api.user.getUsers({
        per_page: DEFAULT_USERS_PER_PAGE,
        page: 1,
      });
      set({ users: data, pagination: meta });
    } catch (error) {
      set({ fetchError: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  nextPage: async () => {
    const state = getState();
    if (!state.users || !state.pagination) return;
    if (state.pagination?.current_page === state.pagination?.last_page) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.user.getUsers({
        per_page: state.pagination.per_page || DEFAULT_USERS_PER_PAGE,
        page: state.pagination.current_page + 1,
        search: state.search,
      });
      set({ users: data, pagination: meta });
    } catch (error) {
      set({ fetchError: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  prevPage: async () => {
    const state = getState();
    if (!state.users || !state.pagination) return;
    if (state.pagination?.current_page === 1) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.user.getUsers({
        per_page: state.pagination.per_page || DEFAULT_USERS_PER_PAGE,
        page: state.pagination.current_page - 1,
        search: state.search,
      });
      set({ users: data, pagination: meta });
    } catch (error) {
      set({ fetchError: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  newUser: async (user) => {
    const state = getState();
    set({ loading: true, createError: null });
    try {
      // @todo(KAN-45): we do not need the confirm_password on new user creation
      //                remove after BE fix
      await api.user.createUser({
        ...user,
        password_confirmation: user.password,
      });
      await state.fetchUsers();
    } catch (e) {
      const error = (e as ErrorResponse).getResponseMessage();
      set({ createError: error });
      throw new Error(error);
    } finally {
      set({ loading: false });
    }
  },
  searchUsers: async (value: string) => {
    const state = getState();

    set({ loading: true });
    try {
      const { data, meta } = await api.user.getUsers({
        per_page: state.pagination?.per_page || DEFAULT_USERS_PER_PAGE,
        page: 1,
        search: value,
      });
      set({ users: data, pagination: meta, search: value });
    } catch (error) {
      set({ fetchError: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  clearCreateErrors: () => {
    set({ createError: null });
  },
  clearState: () => {
    set(initialState);
  },
}));

export default useUsersStore;
