import network from '@/services/network';
import {
  PaginatedResponse,
  Customer,
  ApiResponse,
  InsuranceCreate,
  Insurance,
  SearchParams,
  Funding,
  CalculateSavingsResponse,
  CalculateSavingsRequest,
} from '@/types';
import { ErrorResponse } from '@/utils';
import { mapToNumbers, normalizeData } from '@/utils/system';
import { sortBy } from 'lodash';

const getCustomers = async (params: SearchParams) => {
  try {
    const { data } = await network.get<PaginatedResponse<Customer>>(
      '/customers',
      {
        params,
      },
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getCustomer = async (id: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<Partial<Customer>>>(`/customers/${id}`);

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const createCustomer = async (
  customer?: Partial<Customer>,
): Promise<Partial<Customer>> => {
  try {
    const {
      data: { data },
    } = await network.post<ApiResponse<Partial<Customer>>>(
      '/customers',
      customer
        ? normalizeData(customer, ['id', 'user_id', 'updated_at', 'created_at'])
        : {},
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const updateCustomer = async (id: string, customer: Partial<Customer>) => {
  try {
    const {
      data: { data },
    } = await network.put<ApiResponse<Partial<Customer>>>(
      `/customers/${id}`,
      normalizeData(customer, ['id', 'user_id', 'updated_at', 'created_at']),
    );

    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getFundingsByCustomerId = async (id: string) => {
  try {
    const {
      data: { data },
    } = await network.get<ApiResponse<Funding[]>>(`/customers/${id}/fundings`);
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const getInsurances = async (customerId: string) => {
  try {
    const {
      data: { data },
    } = await network.get<PaginatedResponse<Insurance>>(
      `/customers/${customerId}/insurances`,
    );

    return sortBy(data, 'id');
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const addInsurance = async (
  customerId: string,
  insurance: Partial<InsuranceCreate>,
) => {
  try {
    const { data } = await network.post<Partial<Insurance>>(
      `customers/${customerId}/insurances`,
      mapToNumbers(insurance, ['annual_payment', 'monthly_payment']),
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const updateInsurance = async (
  customerId: string,
  insuranceId: number,
  insurance: Partial<InsuranceCreate>,
) => {
  try {
    const { data } = await network.put<ApiResponse<Partial<Insurance>>>(
      `customers/${customerId}/insurances/${insuranceId}`,
      mapToNumbers(insurance, ['annual_payment', 'monthly_payment'], 'float'),
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const deleteInsurance = async (customerId: string, insuranceId: number) => {
  try {
    const {
      data: { data },
    } = await network.delete<ApiResponse<Partial<Customer>>>(
      `customers/${customerId}/insurances/${insuranceId}`,
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const calculateSaving = async (
  customer_id: number,
  request: CalculateSavingsRequest,
  type: 'diagram' | 'children', // Accepts "diagram" or "children"
) => {
  try {
    const { data } = await network.post<CalculateSavingsResponse[]>(
      `savings/calculate/${type}`, // Dynamic API endpoint based on type
      {
        customer_id,
        ...mapToNumbers(
          mapToNumbers(request, ['target_age', 'current_age']),
          ['savings_rate', 'fundings_sum', 'roi'],
          'float',
        ),
        adjustment: [],
      },
    );
    return data;
  } catch (error) {
    throw new ErrorResponse(error);
  }
};

const customer = {
  getCustomers,
  getCustomer,
  createCustomer,
  updateCustomer,
  getFundingsByCustomerId,
  getInsurances,
  addInsurance,
  updateInsurance,
  deleteInsurance,
  calculateSaving,
};

export default customer;
