import Section from '@/components/ui/Section';
import { Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import HouseholdRevenue from './HouseholdRevenue';
import HouseholdExpenses from './HouseholdExpenses';
import HouseholdVariableExpenses from './HouseholdVariableExpenses';
import HouseholdSummary from './HouseholdSummary';

// ✅ Move initial values outside of JSX
const initialValues = {
  // Revenue Fields
  net_salary: 0,
  parental_allowance: 0,
  child_allowance: 0,
  side_income: 0,
  rental_income: 0,
  // Fixed Expenses
  rent: 0,
  electricity: 0,
  heating: 0,
  water_waste: 0,
  phone: 0,
  internet: 0,
  insurance: 0,
  loans: 0,
  subscriptions: 0,
  // Variable Expenses
  groceries: 0,
  restaurants: 0,
  clothing: 0,
  fuel: 0,
  repairs: 0,
  leisure: 0,
};

const HouseholdCalculator = () => {
  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {() => (
          <Form>
            <Section>
              <HouseholdRevenue />
            </Section>
            <Section>
              <HouseholdExpenses />
            </Section>
            <Section>
              <HouseholdVariableExpenses />
            </Section>
            <Section>
              <HouseholdSummary />
            </Section>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export default HouseholdCalculator;
