import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { customerVisibilityKeys } from '@/constants/form';

import SelectField from '@/components/formControl/SelectField';
import useTranslatedEnum from '@/hooks/useTranslatedEnum';
import TextField from '@/components/formControl/TextField';
import FormCard from '@/components/ui/FormCard';

import {
  CustomerForm,
  CustomerFormProps,
  EmploymentStatus,
  HealthInsuranceStatus,
} from '@/types';
import { getFormKey, showIf } from '@/utils/system';
import { Stack } from '@mui/material';

const HealthInsuranceForm: FC<CustomerFormProps> = ({ formKey }) => {
  const { values } = useFormikContext<CustomerForm>();
  const { t } = useTranslation();

  const [healthInsuranceStatus] = useTranslatedEnum(
    'HealthInsuranceStatus',
    HealthInsuranceStatus,
  );

  return (
    <FormCard>
      <Stack flex={1} flexDirection="row" gap={14}>
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.healthInsuranceStatus,
          <Stack flex={1}>
            <SelectField
              fieldName={getFormKey(formKey, 'health_insurance_status')}
              items={healthInsuranceStatus}
              label={t('customer:newCustomer.form.healthInsuranceStatus.label')}
            />
          </Stack>,
        )}
        {showIf(
          values[formKey],
          'employment_status',
          customerVisibilityKeys.healthInsuranceProvider,
          <Stack flex={1}>
            <TextField
              inline
              hideErrorMessage
              fieldName={getFormKey(formKey, 'health_insurance_provider')}
              label={t(
                'customer:newCustomer.form.healthInsuranceProvider.label',
              )}
            />
          </Stack>,
        )}
      </Stack>
    </FormCard>
  );
};

export default HealthInsuranceForm;
