import Section from '@/components/ui/Section';
import Image from '@/components/ui/Image';

const styles = {
  mainImage: {
    width: '100%',
    marginTop: 2,
  },
};

const NegativeCurve = () => {
  return (
    <Section>
      <Image image="NegativeCurve" sx={styles.mainImage} />
    </Section>
  );
};

export default NegativeCurve;
