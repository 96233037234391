import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 4,
    flexWrap: 'nowrap',
  },
  tableWrapper: {
    borderRadius: '8px',
    overflow: 'hidden',
    flex: '1 1 50%',
    minWidth: '320px',
  },
  tableHeaderCell: {
    backgroundColor: '#1EB2C3',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    width: '50%',
    height: '88px',
    borderRight: '2px solid #E4E7EC',
  },
  tableHeaderRight: {
    backgroundColor: '#1EB2C3',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'left',
    paddingRight: '12px',
    width: '50%',
    height: '88px',
  },
  tableCellLeft: {
    backgroundColor: '#FFFFFF',
    color: '#4A5966',
    fontWeight: 'bold',
    width: '50%',
    borderBottom: '2px solid #E4E7EC',
    borderRight: '2px solid #E4E7EC',
  },
  tableRow: {
    height: '72px',
    backgroundColor: '#fff',
    borderBottom: '2px solid #E4E7EC',
  },
  tableCellRight: {
    backgroundColor: '#FFFFFF',
    textAlign: 'left',
    paddingRight: '12px',
    fontWeight: 'normal',
  },
};

const HouseholdSummary = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<Record<string, number>>();

  const totalIncome = [
    'net_salary',
    'parental_allowance',
    'child_allowance',
    'side_income',
    'rental_income',
  ].reduce((sum, key) => sum + Number(values[key]) || 0, 0);

  const totalFixedExpenses = [
    'rent',
    'electricity',
    'heating',
    'water_waste',
    'phone',
    'internet',
    'insurance',
    'loans',
    'subscriptions',
  ].reduce((sum, key) => sum + Number(values[key]) || 0, 0);

  const totalVariableExpenses = [
    'groceries',
    'restaurants',
    'clothing',
    'fuel',
    'repairs',
    'leisure',
  ].reduce((sum, key) => sum + Number(values[key]) || 0, 0);

  const totalExpenses =
    Number(totalFixedExpenses) + Number(totalVariableExpenses);
  const fixedPercentage = totalIncome ? totalFixedExpenses / totalExpenses : 0;
  const variablePercentage = totalIncome
    ? totalVariableExpenses / totalExpenses
    : 0;
  const surplus = totalIncome - totalExpenses;

  const summaryFields = [
    {
      label: t('customer:household.firstTab.summary.fixed_costs_percentage'),
      value: `${fixedPercentage.toFixed(2)}%`,
    },
    {
      label: t('customer:household.firstTab.summary.variable_costs_percentage'),
      value: `${variablePercentage.toFixed(2)}%`,
    },
    {
      label: t('customer:household.firstTab.summary.surplus'),
      value: `${surplus.toFixed(2)} €`,
    },
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeaderCell}>
                {t('customer:household.firstTab.summary.title')}
              </TableCell>
              <TableCell sx={styles.tableHeaderRight}>
                {totalExpenses.toFixed(2)} €
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryFields.map((row, i) => (
              <TableRow key={i} sx={styles.tableRow}>
                <TableCell sx={styles.tableCellLeft}>{row.label}</TableCell>
                <TableCell sx={styles.tableCellRight}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default HouseholdSummary;
