import { Box, Stack, Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';
import LifeRoadAnlyticsOptions from './LifeRoadAnalyticsSections/LifeRoadAnlyticsOptions';
import TagesshauSection from './LifeRoadAnalyticsSections/TagesshauSection';
import LifeRoadBarChart from './LifeRoadAnalyticsSections/LifeRoadBarChart';
import LifeRoadAnalyticsTable from './LifeRoadAnalyticsSections/LifeRoadAnalyticsTable';
import { useState } from 'react';
import CapitalBuildRow from './LifeRoadAnalyticsSections/CapitalBuildRow';

const LifeRoadAnalyticsPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    {},
  );

  const [activeButtons, setActiveButtons] = useState<Record<string, boolean>>(
    {},
  );
  const [totalSum, setTotalSum] = useState(0);

  const handleSelectedValues = (values: Record<string, string>) => {
    setSelectedValues(values);
  };
  const handleTotalSum = (val: number) => {
    setTotalSum(val);
  };

  const handleActiveButtons = (val: Record<string, boolean>) => {
    setActiveButtons(val);
  };

  const styles = {
    headerContainer: {
      height: '85px',
      p: 2,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '20px',
    },
    link: { display: 'block', width: 120, marginLeft: 'auto' },
    headerImage: {
      width: 120,
      height: 'auto',
      cursor: 'pointer',
      display: 'block',
    },
    text: { color: theme.palette.text.primary },
  };

  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <Box sx={styles.headerContainer}>
          <Typography variant="subtitle2" sx={styles.text}>
            {t('customer:lifeplanAnalytics.section1.title')}
          </Typography>
          <Box
            component="a"
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={styles.link}
          >
            <Image image="Tagesshau" sx={styles.headerImage} />
          </Box>
        </Box>
        <Stack gap={6}>
          <LifeRoadAnlyticsOptions
            onSelectedValuesChange={handleSelectedValues}
            activeButtons={activeButtons}
            onActiveButtonsChange={handleActiveButtons} // FIXED: Pass handler
          />

          <LifeRoadAnalyticsTable
            selectedValues={selectedValues}
            handleTotalSum={handleTotalSum}
          />
        </Stack>
      </Section>
      <Section>
        <CapitalBuildRow
          value={totalSum}
          label={t('customer:lifeplanAnalytics.section2.label')}
        />
      </Section>
      <TagesshauSection />
      <LifeRoadBarChart total={totalSum} />
    </Stack>
  );
};

export default LifeRoadAnalyticsPage;
