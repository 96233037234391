import { Box, Stack, TextField, Typography, Paper } from '@mui/material';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { euro } from '@/utils/math';

const styles = {
  container: {
    borderRadius: 2,
    backgroundColor: '#f2f8fd',
    p: 3,
  },
  sectionBox: {
    backgroundColor: '#E5EDF4',
    borderRadius: '12px',
    height: '210px',
    py: '26px',
    px: '36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    mt: '18px',
  },
  inputWrapper: {
    display: 'flex',
    gap: 5,
  },
  inputBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: '14px',
    marginBottom: '6px',
    fontWeight: 500,
    color: '#344054',
  },
  inputField: {
    backgroundColor: 'white',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      height: '44px',
      backgroundColor: 'white',
      borderColor: '#D0D5DD',
      borderRadius: '8px',
    },
  },
  bottomRow: {
    marginTop: '18px',
  },
};

const EstgForm = () => {
  const { t } = useTranslation();

  const [contribution, setContribution] = useState('');
  const [stateSupport, setStateSupport] = useState('');

  const total = useMemo(() => {
    const own = parseFloat(contribution) || 0;
    const state = parseFloat(stateSupport) || 0;
    return euro(own + state).format();
  }, [contribution, stateSupport]);

  return (
    <Paper elevation={0} sx={styles.container}>
      <Stack gap={1}>
        <Typography variant="subtitle2" fontWeight={600}>
          {t('customer:estg.title')}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t('customer:estg.subtitle')}
        </Typography>

        <Box sx={styles.sectionBox}>
          <Box sx={styles.inputWrapper}>
            <Box sx={styles.inputBox}>
              <Typography sx={styles.label}>
                {t('customer:estg.ownContribution')}
              </Typography>
              <TextField
                value={contribution}
                onChange={(e) => setContribution(e.target.value)}
                fullWidth
                sx={styles.inputField}
              />
            </Box>
            <Box sx={styles.inputBox}>
              <Typography sx={styles.label}>
                {t('customer:estg.stateSupport')}
              </Typography>
              <TextField
                value={stateSupport}
                onChange={(e) => setStateSupport(e.target.value)}
                fullWidth
                sx={styles.inputField}
              />
            </Box>
          </Box>

          <Box sx={styles.bottomRow}>
            <Typography sx={styles.label}>
              {t('customer:estg.total')}
            </Typography>
            <TextField
              value={total}
              fullWidth
              sx={styles.inputField}
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};

export default EstgForm;
