import Section from '@/components/ui/Section';
import Image from '@/components/ui/Image';

const styles = {
  width: '100%',
  mt: 2,
};

const InterestRateSection = () => {
  return (
    <Section>
      <Image image="InterestRate" sx={styles} />
    </Section>
  );
};

export default InterestRateSection;
