import { TableCell, TableHead as MUITableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import {
  HeaderCell,
  TableHeadProps,
} from '@/components/ui/TableGenerator/types';
import { map } from 'lodash';

const TableHead: FC<TableHeadProps> = ({ values }) => {
  const renderTableCell = ({ value, colspan }: HeaderCell, i: number) => {
    return (
      <TableCell colSpan={colspan} key={i}>
        {value}
      </TableCell>
    );
  };

  const renderTableHeadRow = (value: HeaderCell[], i: number) => {
    return <TableRow key={i}>{map(value, renderTableCell)}</TableRow>;
  };

  return <MUITableHead>{map(values, renderTableHeadRow)}</MUITableHead>;
};

export default TableHead;
