import Section from '@/components/ui/Section';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
  LabelList,
} from 'recharts';

// Function to calculate monthly payment for ETF and Sparbuch
const calculateMonthlyPayment = (
  futureValue: number,
  annualRate: number,
  years: number,
): number => {
  let r = annualRate / 12 / 100; // Convert annual % to monthly decimal
  let n = years * 12; // Total months
  return r > 0 ? (futureValue * r) / (Math.pow(1 + r, n) - 1) : futureValue / n; // Avoid division by zero
};

// Function to calculate loan payment for Kredit
const calculateLoanPayment = (
  loanAmount: number,
  annualRate: number,
  years: number,
): number => {
  let r = annualRate / 12 / 100; // Monthly interest rate
  let n = years * 12; // Total months
  return (loanAmount * r) / (1 - Math.pow(1 + r, -n));
};

// Function to generate adaptive Y-axis ticks (ensures unique 0)
const getAdaptiveTicks = (maxValue: number): number[] => {
  if (maxValue === 0) return [0]; // Handle case when max value is 0

  const step = Math.ceil(maxValue / 5); // Define step size
  let ticks = Array.from({ length: 6 }, (_, i) => i * step); // Generate ticks dynamically

  // Ensure only one "0.00€" tick and remove near-zero duplicate values
  return Array.from(new Set(ticks.filter((t, i) => i === 0 || t > 0))); //  Ensures only one zero
};

// Function to format large Y-axis values (e.g., 10,000 → 10k)
const formatYAxisTicks = (value: number) => {
  if (Math.abs(value) < 0.01) return '0.00€';
  if (value >= 1000) return `${(value / 1000).toFixed(1)}k€`;
  return `${value.toFixed(2)}€`;
};

interface LifeRoadBarChartProps {
  total: number;
}

const LifeRoadBarChart: React.FC<LifeRoadBarChartProps> = ({ total }) => {
  const { t } = useTranslation();

  const data = [
    { name: 'Kredit', value: calculateLoanPayment(total, 8, 20) }, // 8% annual rate, 20 years
    { name: 'Sparbuch', value: calculateMonthlyPayment(total, 0, 20) }, // 0% interest (fixed savings)
    { name: 'ETF', value: calculateMonthlyPayment(total, 8, 20) }, // 8% return over 20 years
  ];

  const maxValue = Math.max(...data.map((d) => d.value));

  const barColors = ['#00ABEA', '#85D7F5', '#82B1C3'];

  const customLegend = () => (
    <Box display="flex" justifyContent="center" gap={3} marginTop={2}>
      {data.map((entry, index) => (
        <Box
          key={`legend-item-${index}`}
          display="flex"
          alignItems="center"
          gap={1}
        >
          <Box
            width="20px"
            height="20px"
            bgcolor={barColors[index]}
            borderRadius="4px"
          />
          <Typography>{entry.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Section>
      <Stack gap="19px">
        <Typography variant="subtitle2">
          {t('customer:calculator.section1.title')}
        </Typography>
        <Box padding={3} bgcolor={'#fff'} borderRadius={'8px'}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={data}
              margin={{ top: 40, right: 30, left: 50, bottom: 40 }}
              style={{ backgroundColor: '#fff' }}
            >
              <CartesianGrid
                strokeDasharray="none"
                horizontal
                vertical={false}
                stroke="#dcdcdc"
              />
              <XAxis
                dataKey="name"
                tick={false}
                tickMargin={15}
                tickLine={false}
              />
              <YAxis
                width={60}
                tickFormatter={formatYAxisTicks}
                ticks={getAdaptiveTicks(maxValue)}
                allowDecimals={false}
                domain={[0, 'auto']}
                axisLine={false}
                tick={{ fill: '#000' }}
                tickMargin={10}
                tickLine={false}
              />

              <Tooltip formatter={(value) => `${Number(value).toFixed(2)}€`} />
              <Legend content={customLegend} />

              <Bar dataKey="value">
                <LabelList
                  dataKey="value"
                  position="top"
                  formatter={(value: number) => `${value.toFixed(2)}€`}
                />
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={barColors[index]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Section>
  );
};

export default LifeRoadBarChart;
