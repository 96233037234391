import { create } from 'zustand';

import { CustomerStore, CustomerState } from './types';
import api from '@/services/api';
import { ErrorResponse } from '@/utils';
import { DEFAULT_USERS_PER_PAGE } from '@/constants/env';

const initialState: CustomerState = {
  customers: null,
  pagination: null,
  loading: false,
  error: null,
};

const useCustomersStore = create<CustomerStore>((set, getState) => ({
  ...initialState,
  fetchCustomers: async () => {
    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers({
        per_page: DEFAULT_USERS_PER_PAGE,
        page: 1,
      });
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  nextPage: async () => {
    const state = getState();
    if (!state.customers || !state.pagination) return;
    if (state.pagination?.current_page === state.pagination?.last_page) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers({
        per_page: state.pagination.per_page || DEFAULT_USERS_PER_PAGE,
        page: state.pagination.current_page + 1,
        search: state.search,
      });
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  prevPage: async () => {
    const state = getState();
    if (!state.customers || !state.pagination) return;
    if (state.pagination?.current_page === 1) return;

    set({ loading: true });
    try {
      const { data, meta } = await api.customer.getCustomers({
        per_page: state.pagination.per_page || DEFAULT_USERS_PER_PAGE,
        page: state.pagination.current_page - 1,
        search: state.search,
      });
      set({ customers: data, pagination: meta });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  searchCustomers: async (value: string) => {
    const state = getState();

    try {
      const { data, meta } = await api.customer.getCustomers({
        per_page: state.pagination?.per_page || DEFAULT_USERS_PER_PAGE,
        page: 1,
        search: value,
      });
      set({ customers: data, pagination: meta, search: value });
    } catch (error) {
      set({ error: (error as ErrorResponse).getType() });
    } finally {
      set({ loading: false });
    }
  },
  clearState: () => {
    set(initialState);
  },
}));

export default useCustomersStore;
