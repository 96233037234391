import { Box, Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';

const styles = {
  container: {
    padding: 2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  image: {
    width: 120,
    height: 'auto',
    cursor: 'pointer',
  },
  subtitle: {
    marginBottom: 0.5,
    fontStyle: 'italic',
  },
  realEstateText: {
    marginBottom: 2,
  },
  mainImage: {
    width: '100%',
    marginTop: 2,
  },
};

const KinderSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Section>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              {t('customer:lifeplan.germanSavings')}
            </Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.primary}
              sx={styles.subtitle}
            >
              {t('customer:lifeplan.germanSavingsDate')}
            </Typography>
          </Box>
          <Box
            sx={styles.link}
            component="a"
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image image="SternLogo" sx={styles.image} />
          </Box>
        </Box>
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          sx={styles.realEstateText}
        >
          {t('customer:lifeplan.realEstateCostWarning')}
        </Typography>
        <Image image="KinderCards" sx={styles.mainImage} />
      </Box>
    </Section>
  );
};

export default KinderSection;
