import Section from '@/components/ui/Section';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

// TODO mock data
const data = [
  { name: 'Aktueller Rentenanspruch', value: 0.12 },
  { name: 'Neuer Rentenanspruch', value: 0.6 },
];

const PensionChart = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Stack gap="19px">
        <Typography variant="subtitle2">
          {t('customer:calculator.section1.title')}
        </Typography>
        <Box padding={3} bgcolor={'#fff'} borderRadius={'8px'}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={data}
              margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
              style={{ backgroundColor: '#fff' }}
            >
              <CartesianGrid
                strokeDasharray="none"
                horizontal={true}
                vertical={false}
                stroke="#dcdcdc"
              />
              <XAxis
                dataKey="name"
                angle={0}
                textAnchor="middle"
                interval={0}
                tick={{ fill: '#000' }}
                tickMargin={15}
                tickLine={false}
              />
              <YAxis
                tickFormatter={(value) => `${value}€`}
                ticks={[0, 0.25, 0.5, 0.75, 1]}
                axisLine={false}
                tick={{ fill: '#000' }}
                tickMargin={10}
                tickLine={false}
              />
              <Tooltip formatter={(value) => `${value}€`} />
              <Bar dataKey="value">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index % 2 === 0 ? '#F59418' : '#85D7F5'}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Section>
  );
};

export default PensionChart;
