import { Box, Typography } from '@mui/material';
import { euro } from '@/utils/math';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '100%',
    height: '72px',
  },
  leftBox: {
    color: '#fff',
    fontWeight: 'bold',
    padding: '16px',
    flex: '1 1 50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rightBox: {
    backgroundColor: '#fff',
    color: '#333',
    fontWeight: 'bold',
    padding: '16px',
    flex: '1 1 50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

interface CalculatorSmallTableProps {
  leftText?: string;
  rightText?: string | number;
  leftBgColor?: string;
}

const CalculatorSmallTable = ({
  leftText,
  rightText = 0,
  leftBgColor = '#1EB2C3',
}: CalculatorSmallTableProps) => {
  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.leftBox, backgroundColor: leftBgColor }}>
        <Typography>{leftText}</Typography>
      </Box>
      <Box sx={styles.rightBox}>
        <Typography>{euro(rightText).format()}</Typography>
      </Box>
    </Box>
  );
};

export default CalculatorSmallTable;
