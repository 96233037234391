import { Box, Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';

const FocusEmpty = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  const styles = {
    container: { p: 2 },
    link: { display: 'block', width: '100%' },
    image: {
      width: 120,
      height: 'auto',
      cursor: 'pointer',
      marginLeft: 'auto',
      display: 'block',
      marginBottom: '40px',
    },
    text: { color: theme.palette.text.primary },
  };
  return (
    <Section>
      <Box sx={styles.container}>
        <Box
          component="a"
          href="https://www.google.com"
          target="_blank"
          rel="noopener noreferrer"
          sx={styles.link}
        >
          <Image image="Focus" sx={styles.image} />
        </Box>
        <Typography variant="body2" sx={styles.text}>
          {t('customer:lifeplan.focusEmptyText')}
        </Typography>
      </Box>
    </Section>
  );
};

export default FocusEmpty;
