import { BarChart } from '@mui/x-charts/BarChart';
import { Stack, Typography } from '@mui/material';
import { euro } from '@/utils/math';
import Section from '@/components/ui/Section';
import { SXStyleSheet } from '@/utils';
import { useTranslation } from 'react-i18next';

const EstgChart = () => {
  const { t } = useTranslation();

  const seriesData = [
    {
      label: t('customer:estg.chart.riester'),
      data: [1000],
      color: '#00ABEA',
    },
    {
      label: t('customer:estg.chart.zulagen'),
      data: [800],
      color: '#85D7F5',
    },
    {
      label: t('customer:estg.chart.rendite'),
      data: [500],
      color: '#A7D7DD',
    },
    {
      label: t('customer:estg.chart.zugewinn'),
      data: [300],
      color: '#005C83',
    },
  ];

  return (
    <Section>
      <Stack gap="29px">
        <Typography variant="subtitle2">
          {t('customer:estg.chart.title')}
        </Typography>

        <Stack sx={style.barChartContainer}>
          <BarChart
            height={536}
            margin={{ bottom: 102 }}
            sx={style.chart}
            grid={{ horizontal: true }}
            borderRadius={8}
            barLabel={(item) =>
              item.value ? euro(item.value as number).format() : ''
            }
            slotProps={{
              legend: {
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: { top: 100 },
                itemGap: 48,
              },
            }}
            series={seriesData}
            xAxis={[
              {
                scaleType: 'band',
                data: [''],
                disableLine: true,
                tickSize: 0,
                tickLabelStyle: {
                  display: 'none',
                },
              },
            ]}
            yAxis={[
              {
                disableLine: true,
                disableTicks: true,
                tickNumber: 4,
              },
            ]}
          />
        </Stack>
      </Stack>
    </Section>
  );
};

export default EstgChart;

const style = SXStyleSheet.create({
  chart: {
    '& .MuiChartsLegend-mark': {
      rx: '4px',
    },
    '& .MuiChartsAxis-tick': {
      stroke: 'transparent !important',
    },
    '& .MuiBarLabel-root': {
      fontSize: '16px',
      fill: '#FFFFFF',
      fontWeight: 600,
    },
  },
  barChartContainer: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    padding: 6,
    paddingBottom: 2,
  },
});
