import { SXStyleSheet } from '@/utils';
import {
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

const style = SXStyleSheet.create({
  dividedCellContainer: {
    px: 0,
  },
  divider: {
    height: '100%',
    width: '1px',
    backgroundColor: 'divider',
  },
  table: {
    borderRadius: 2,
    overflow: 'hidden',
    [`& .${tableHeadClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        '&:first-of-type': {
          minWidth: 274,
          width: 274,
        },
        fontWeight: 700,
        fontSize: 16,
        height: 88,
        p: 0,
        px: '24px',
        color: 'primary.contrastText',
        backgroundColor: '#1EB2C3',
        borderColor: 'divider',
        borderRight: '1px solid',
        borderRightColor: 'divider',
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    [`& .${tableRowClasses.root}`]: {
      '&:last-of-type': {
        [`& .${tableCellClasses.root}`]: {
          pl: '24px',
        },
      },
      [`& .${tableCellClasses.root}.total-cell`]: {
        backgroundColor: '#E6EEF6',
        color: 'text.primary',
        fontSize: '18px',
        fontWeight: 600,
      },
      [`& .${tableCellClasses.root}.accent-cell`]: {
        backgroundColor: 'secondary.main',
        color: 'primary.contrastText',
        overflow: 'auto',
        padding: '24px',
        maxWidth: 110,
        fontWeight: 700,
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      },
      [`& .${tableCellClasses.root}`]: {
        '&:first-of-type': {
          maxWidth: 274,
          width: 274,
        },
        fontSize: 14,
        p: 0,
        height: '72px',
        backgroundColor: '#FFFFFF',
        borderColor: 'divider',
        borderRight: '1px solid',
        borderRightColor: 'divider',
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
  },
  tableResults: {
    borderBottomRightRadius: 2,
    borderBottomLeftRadius: 2,
    overflow: 'hidden',
    [`& .${tableRowClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        fontWeight: 700,
        fontSize: 14,
        p: 0,
        px: '24px',
        height: '72px',
        backgroundColor: '#1EB2C3',
        color: 'primary.contrastText',
        borderColor: 'divider',
        borderRight: '1px solid',
        borderRightColor: 'divider',
        '&:last-child': {
          borderRight: 'none',
        },
      },
      [`& .${tableCellClasses.root}:first-of-type`]: {
        backgroundColor: '#E6EEF6',
        width: '494px',
        color: 'text.primary',
        fontSize: '18px',
        fontWeight: 600,
      },
      '& .saving-cell': {
        backgroundColor: '#1AA0B0',
      },
    },
  },
  dividedCell: {
    backgroundColor: '#079DAF',
  },
});

export default style;
