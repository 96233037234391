import { Divider, Stack, Table, TableCell, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { FieldArray, Formik, FormikProps } from 'formik';
import { filter, map, noop } from 'lodash';

import AddPropertyInsurance from '@/components/tables/PropertyInsurance/AddPropertyInsurance';
import PropertyInsuranceSum from '@/components/tables/PropertyInsurance/PropertyInsuranceSum';
import PropertyInsuranceRow from '@/components/tables/PropertyInsurance/PropertyInsuranceRow';

import { getFormKey } from '@/utils/system';
import useCustomerStore from '@/store/customer';
import { Insurance } from '@/types';
import { useEffect, useMemo, useRef } from 'react';

const PropertyInsurance = () => {
  const formRef = useRef<FormikProps<{ insurances: Insurance[] }>>(null);
  const { t } = useTranslation();
  const { insurances, addInsurance, updateInsurance, deleteInsurance } =
    useCustomerStore();

  const propertyInsurances = useMemo(() => {
    return filter(insurances, { category: 'property_liability' });
  }, [insurances]);

  const handleUpdate = ({ id, ...insurance }: Partial<Insurance>) => {
    if (id) {
      updateInsurance(id, insurance);
    } else if (insurance.type) {
      addInsurance({
        ...insurance,
        category: 'property_liability',
      });
    }
  };

  useEffect(() => {
    formRef.current?.setValues({ insurances: propertyInsurances || [] });
  }, [propertyInsurances]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ insurances: propertyInsurances || [] }}
      onSubmit={noop}
    >
      <Stack>
        <Table sx={style.table}>
          <TableHead>
            <TableCell>{t('customer:financeInfo.table.insurance')}</TableCell>
            <TableCell>{t('customer:financeInfo.table.company')}</TableCell>
            <TableCell
              sx={{ minWidth: 220 }}
              className="no-padding"
              colSpan={2}
            >
              <Stack width="100%" height="100%" alignItems="center">
                <Stack justifyContent="center" flex={1}>
                  {t('customer:financeInfo.table.contribution')}
                </Stack>
                <Stack
                  sx={style.dividedCell}
                  width="100%"
                  direction="row"
                  alignItems="center"
                  gap={2}
                  flex={1}
                >
                  <Stack flex={1} alignItems="center">
                    {t('customer:financeInfo.table.monthly')}
                  </Stack>
                  <Divider color="divider" sx={style.divider} />
                  <Stack flex={1} alignItems="center">
                    {t('customer:financeInfo.table.yearly')}
                  </Stack>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>{t('customer:financeInfo.table.newCompany')}</TableCell>
            <TableCell
              sx={{ minWidth: 220 }}
              className="no-padding"
              colSpan={2}
            >
              <Stack width="100%" height="100%" alignItems="center">
                <Stack justifyContent="center" flex={1}>
                  {t('customer:financeInfo.table.newContributions')}
                </Stack>
                <Stack
                  sx={style.dividedCell}
                  width="100%"
                  direction="row"
                  alignItems="center"
                  gap={2}
                  flex={1}
                >
                  <Stack flex={1} alignItems="center">
                    {t('customer:financeInfo.table.monthly')}
                  </Stack>
                  <Divider color="divider" sx={style.divider} />
                  <Stack flex={1} alignItems="center">
                    {t('customer:financeInfo.table.yearly')}
                  </Stack>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell width="50px">
              {t('customer:financeInfo.table.action')}
            </TableCell>
          </TableHead>
          <FieldArray
            name="insurances"
            render={(helpers) => {
              return map(helpers.form.values.insurances, (_, i) => (
                <PropertyInsuranceRow
                  index={i}
                  formKey={getFormKey('insurances', i)}
                  onDelete={deleteInsurance}
                  onUpdate={handleUpdate}
                />
              ));
            }}
          />
        </Table>
        <PropertyInsuranceSum />
        <AddPropertyInsurance />
      </Stack>
    </Formik>
  );
};

export default PropertyInsurance;
