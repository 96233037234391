import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import CellTextField from '@/components/formControl/CellTextField';
import { useMemo } from 'react';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 4,
    flexWrap: 'nowrap',
  },
  tableWrapper: {
    borderRadius: '8px',
    overflow: 'hidden',
    flex: '1 1 50%',
    minWidth: '320px',
  },
  tableHeaderCell: {
    backgroundColor: '#00ABEA',
    color: '#fff',
    fontWeight: 'bold',
    fontSize: '16px',
    width: '50%',
    height: '88px',
  },
  tableCellLeft: {
    backgroundColor: '#FFFFFF',
    color: '#4A5966',
    fontWeight: 'bold',
    width: '50%',
    borderBottom: '2px solid #E4E7EC',
    borderRight: '2px solid #E4E7EC',
  },
  tableRow: {
    height: '72px',
    backgroundColor: '#fff',
    borderBottom: '2px solid #E4E7EC',
  },
  sumRowLeft: {
    backgroundColor: '#E4E7EC',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#4A5966',
    padding: '12px',
    height: '72px',
  },
  sumRowRight: {
    backgroundColor: '#F59418',
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'right',
    padding: '12px',
    height: '72px',
  },
};

const HouseholdVariableExpenses = () => {
  const { t } = useTranslation();

  const { values } = useFormikContext<Record<string, number>>();

  const variableExpenseFields = useMemo(
    () => [
      {
        label: t('customer:household.firstTab.variable_expenses.groceries'),
        fieldName: 'groceries',
      },
      {
        label: t('customer:household.firstTab.variable_expenses.restaurants'),
        fieldName: 'restaurants',
      },
      {
        label: t('customer:household.firstTab.variable_expenses.clothing'),
        fieldName: 'clothing',
      },
      {
        label: t('customer:household.firstTab.variable_expenses.fuel'),
        fieldName: 'fuel',
      },
      {
        label: t('customer:household.firstTab.variable_expenses.repairs'),
        fieldName: 'repairs',
      },
      {
        label: t('customer:household.firstTab.variable_expenses.leisure'),
        fieldName: 'leisure',
      },
    ],
    [t],
  );

  const totalVariableExpenses = variableExpenseFields.reduce(
    (sum, field) => sum + (Number(values[field.fieldName]) || 0),
    0,
  );

  return (
    <Form>
      <Box sx={styles.container}>
        <Box sx={styles.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeaderCell}>
                  {t('customer:household.firstTab.variable_expenses.title')}
                </TableCell>
                <TableCell sx={styles.tableHeaderCell} />
              </TableRow>
            </TableHead>
            <TableBody>
              {variableExpenseFields.map((row, i) => (
                <TableRow key={i} sx={styles.tableRow}>
                  <TableCell sx={styles.tableCellLeft}>{row.label}</TableCell>
                  <CellTextField
                    fieldName={row.fieldName}
                    valueEndAdornment="€"
                    endAdornment="€"
                  />
                </TableRow>
              ))}
              {/* Sum Row */}
              <TableRow>
                <TableCell sx={styles.sumRowLeft}>
                  {t('customer:household.firstTab.variable_expenses.total')}
                </TableCell>
                <TableCell sx={styles.sumRowRight}>
                  {Number(totalVariableExpenses).toFixed(2)} €
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Form>
  );
};

export default HouseholdVariableExpenses;
