import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFormField from '@/hooks/useFormField';
import { ProductsTable } from '@/types';
import { euro } from '@/utils/math';

const SumRowProduct = () => {
  const { t } = useTranslation();
  const { value } = useFormField<ProductsTable[]>('products', []);

  const totalContribution = value.reduce((sum, row) => {
    const val = parseFloat(String(row.contribution || '0'));

    return sum + (isNaN(val) ? 0 : val);
  }, 0);

  const totalFunding = value.reduce((sum, row) => {
    const val = parseFloat(String(row.funding_immediate || '0'));

    return sum + (isNaN(val) ? 0 : val);
  }, 0);

  return (
    <TableRow>
      <TableCell className="total-cell" colSpan={2}>
        {t('customer:lifeInsurance.table.total')}
      </TableCell>
      <TableCell className="accent-cell" colSpan={1}>
        {euro(totalContribution).format()}
      </TableCell>
      <TableCell className="accent-cell" colSpan={1}>
        {euro(totalFunding).format()}
      </TableCell>
      <TableCell className="accent-cell" colSpan={1} />
    </TableRow>
  );
};

export default SumRowProduct;
