import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import { LifeInsuranceList } from '@/constants/insurances';
import CellTextField from '@/components/formControl/CellTextField';
import { useMemo } from 'react';
import { Insurance } from '@/types';
import { useFormikContext } from 'formik';
import { euro, futureValue } from '@/utils/math';
import { useTranslation } from 'react-i18next';

const SavingCompareRow = () => {
  const { submitForm, values } = useFormikContext<Insurance>();
  const { t } = useTranslation();

  const handleUpdate = () => {
    submitForm?.();
  };

  const fv = useMemo(() => {
    return futureValue(
      (values.roi || 0) / 100 / 12,
      12 * (values.duration_in_years || 0),
      -(values.monthly_payment || 0),
      -(values.credit_balance || 0),
    );
  }, [
    values.credit_balance,
    values.duration_in_years,
    values.monthly_payment,
    values.roi,
  ]);

  const fvNew = useMemo(() => {
    return futureValue(
      (values.new_roi || 0) / 100 / 12,
      12 * (values.duration_in_years || 0),
      -(values.new_monthly_payment || 0),
      -(values.credit_balance || 0),
    );
  }, [
    values.credit_balance,
    values.duration_in_years,
    values.new_monthly_payment,
    values.new_roi,
  ]);

  return (
    <TableBody>
      <TableRow>
        <CellTextField fieldName="provider" onBlur={handleUpdate} />
        <CellSelectField
          fieldName="type"
          items={LifeInsuranceList}
          onSelect={handleUpdate}
        />
        <CellTextField
          fieldName="roi"
          tableCellProps={{ width: 110 }}
          endAdornment="%"
          valueEndAdornment="%"
          onBlur={handleUpdate}
        />
        <CellTextField
          tableCellProps={{ width: 110 }}
          fieldName="duration_in_years"
          onBlur={handleUpdate}
        />
        <CellTextField
          tableCellProps={{ width: 110 }}
          fieldName="credit_balance"
          endAdornment="€"
          valueEndAdornment="€"
          onBlur={handleUpdate}
        />
        <CellTextField
          tableCellProps={{ width: 110 }}
          fieldName="monthly_payment"
          endAdornment="€"
          valueEndAdornment="€"
          onBlur={handleUpdate}
        />
        <TableCell>
          <Stack px={6}>{euro(fv.toNumber()).format()}</Stack>
        </TableCell>
        <TableCell>
          <Stack px={6}>{t('customer:savings.before')}</Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <CellTextField fieldName="new_provider" onBlur={handleUpdate} />
        <CellSelectField
          fieldName="type"
          items={LifeInsuranceList}
          onSelect={handleUpdate}
        />
        <CellTextField
          fieldName="new_roi"
          endAdornment="%"
          valueEndAdornment="%"
          onBlur={handleUpdate}
        />
        <CellTextField fieldName="duration_in_years" onBlur={handleUpdate} />
        <CellTextField
          fieldName="credit_balance"
          endAdornment="€"
          valueEndAdornment="€"
          onBlur={handleUpdate}
        />
        <CellTextField
          tableCellProps={{ width: 110 }}
          fieldName="new_monthly_payment"
          endAdornment="€"
          valueEndAdornment="€"
          onBlur={handleUpdate}
        />
        <TableCell>
          <Stack px={6}>{euro(fvNew.toNumber()).format()}</Stack>
        </TableCell>
        <TableCell>
          <Stack px={6}>{t('customer:savings.after')}</Stack>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default SavingCompareRow;
