import { Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';

const styles = {
  image: {
    width: '100%',
    marginTop: 2,
  },
};

const PositiveCurve = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Section>
      <Typography variant="subtitle2" color={theme.palette.text.primary}>
        {t('customer:lifeplan.guaranteedInterestRate')}
      </Typography>
      <Image image="PositiveCurve" sx={styles.image} />
    </Section>
  );
};

export default PositiveCurve;
