const env = {
  baseUrl: process.env.REACT_APP__BASE_URL,
};

export type Env = typeof env;

// @todo: replace it with height per row
export const DEFAULT_USERS_PER_PAGE = 6;

export default env;
