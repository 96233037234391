import { TableBody, TableCell, TableRow as MUITableRow } from '@mui/material';
import { FC } from 'react';
import { RowCell, RowProps } from '@/components/ui/TableGenerator/types';
import { map } from 'lodash';

const TableRows: FC<RowProps> = ({ values }) => {
  const renderTableCell = (
    { value, color, bgColor, transform, colspan }: RowCell,
    i: number,
  ) => {
    const cellStyles = {
      color: color || 'inherit',
      backgroundColor: `${bgColor} !important` || 'transparent',
    };

    return (
      <TableCell sx={cellStyles} key={i} colSpan={colspan}>
        {transform ? transform(value) : value}
      </TableCell>
    );
  };

  const renderTableRow = (value: RowCell[], i: number) => {
    return <MUITableRow key={i}>{map(value, renderTableCell)}</MUITableRow>;
  };

  return <TableBody>{map(values, renderTableRow)}</TableBody>;
};

export default TableRows;
