import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 4,
  },
  section: {
    flex: '1 1 45%',
    maxWidth: '740px',
    minWidth: '320px',
  },
  tableWrapper: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  tableCellLeft: (highlight: boolean, index: number) => ({
    backgroundColor: highlight
      ? '#F59418'
      : index % 2 === 0
        ? '#1AA0B0'
        : '#1EB2C3',
    color: '#fff',
    fontWeight: 'bold',
    width: '50%',
  }),
  tableCellRight: (highlight: boolean, index: number) => ({
    backgroundColor: index % 2 === 0 ? '#E9F4FB' : '#FFFFFF',
    fontWeight: highlight ? 'bold' : 'normal',
    textAlign: 'start',
  }),
  tableRow: {
    height: '72px',
  },
};

const KLVTable = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t('customer:klv.table.legalFraud'),
      values: [
        { label: t('customer:klv.table.currentBalance'), value: '0.00 €' },
        {
          label: t('customer:klv.table.monthlyContribution'),
          value: '100.60 €',
        },
        { label: t('customer:klv.table.insuranceStart'), value: '2008' },
        {
          label: t('customer:klv.table.guaranteedInterestRate'),
          value: '2.25%',
        },
        { label: t('customer:klv.table.duration'), value: '25' },
        {
          label: t('customer:klv.table.finalCapital'),
          value: '40,461.62 €',
          highlight: true,
        },
      ],
    },
    {
      title: t('customer:klv.table.etfPolicy'),
      values: [
        { label: t('customer:klv.table.currentBalance'), value: '0.00 €' },
        {
          label: t('customer:klv.table.monthlyContribution'),
          value: '100.60 €',
        },
        { label: '-', value: '-' },
        { label: t('customer:klv.table.return'), value: '7.00%' },
        { label: t('customer:klv.table.duration'), value: '25' },
        {
          label: t('customer:klv.table.finalCapital'),
          value: '81,493.21 €',
          highlight: true,
        },
      ],
    },
  ];

  return (
    <Box sx={styles.container}>
      {data.map((section, index) => (
        <Box key={index} sx={styles.section}>
          <Typography variant="subtitle2" gutterBottom>
            {section.title}
          </Typography>
          <Box sx={styles.tableWrapper}>
            <Table>
              <TableBody>
                {section.values.map((row, i) => (
                  <TableRow key={i} sx={styles.tableRow}>
                    <TableCell
                      sx={styles.tableCellLeft(row.highlight || false, i)}
                    >
                      {row.label}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={styles.tableCellRight(row.highlight || false, i)}
                    >
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default KLVTable;
