import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import Badge from '@/components/ui/Badge';
import Pagination from '@/components/ui/Pagination';
import NewUserDialog from '@/components/modals/NewUserDialog';
import Header from '@/components/Header';
import Table from '@/components/ui/Table';
import AdminTableRow from 'src/components/ui/UserTableRow';

import useAuthStore from '@/store/auth';
import useUsersStore from '@/store/users';
import { Customer, User } from '@/types';
import { NewUserFormValues } from '@/components/forms/NewUserForm';
import { getTranslationKey } from '@/utils/system';
import { AdminTableColumns } from '@/constants/table';
import { SXStyleSheet } from '@/utils';
import AdminFilter from '@/components/AdminFilter';
import FormCard from '@/components/ui/FormCard';
import { split } from 'lodash';

const AdminView = () => {
  const [newUserFlowModalOpened, setNewUserFlowModalOpened] =
    useState<boolean>(false);

  const { t } = useTranslation();

  const { user } = useAuthStore();
  const {
    users,
    searchUsers,
    pagination,
    nextPage,
    prevPage,
    createError,
    fetchUsers,
    newUser,
    clearCreateErrors,
  } = useUsersStore();

  const getTableData = useCallback(async () => {
    fetchUsers().catch(console.error);
  }, [fetchUsers]);

  useEffect(() => {
    getTableData().catch();
  }, [getTableData]);

  const handleToggleModal = (state: boolean) => () => {
    if (!state) {
      clearCreateErrors();
    }

    setNewUserFlowModalOpened(state);
  };

  const handleSubmit = async (values: NewUserFormValues) => {
    try {
      await newUser(values);
      setNewUserFlowModalOpened(false);
    } catch (error) {
      console.error(error);
    }
  };

  const renderTableRow = (item: User | Customer) => {
    return <AdminTableRow data={item as User} key={item.id} />;
  };

  const renderTableTitle = useCallback(() => {
    return (
      <Stack>
        <Stack direction="row" alignItems="center" gap={2} pb={5}>
          <Typography variant="subtitle1">
            {t('admin:home.userTable.title')}
          </Typography>
          <Badge
            text={t('admin:home.userTable.userCount', {
              count: pagination?.total,
            })}
          />
        </Stack>
      </Stack>
    );
  }, [pagination?.total, t]);

  return (
    <Stack sx={style.container}>
      <Header
        title={t('admin:home.title', { name: split(user?.name, ' ')[0] })}
      />
      <Stack sx={style.contentWrapper}>
        <AdminFilter
          onSearchValueChange={searchUsers}
          onAddClick={handleToggleModal(true)}
        />
        <FormCard flex={1} overflow="hidden" pb={4}>
          <Table
            trKey="admin:home.userTable.tableKeys"
            data={users || []}
            renderRow={renderTableRow}
            renderTableTitle={renderTableTitle}
            headerItems={AdminTableColumns}
          />
          <Pagination
            page={pagination?.current_page || 0}
            total={pagination?.last_page || 0}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </FormCard>
      </Stack>
      <NewUserDialog
        open={newUserFlowModalOpened}
        onSubmit={handleSubmit}
        onClose={handleToggleModal(false)}
        error={
          !!createError &&
          t(
            getTranslationKey(
              `errors:response.${createError}`,
              `errors:network.BadRequest`,
            ),
          )
        }
      />
    </Stack>
  );
};

const style = SXStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'background.screen',
  },
  contentWrapper: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: 'background.card',
    mx: 6,
    mb: 6,
    borderRadius: 3,
    p: 6,
    gap: 6,
  },
});

export default AdminView;
