import { FC } from 'react';

import { TableCell, TableCellProps } from '@mui/material';
import SingleSelect, { SingleSelectProps } from '@/components/ui/SingleSelect';

export interface TextFieldProps extends SingleSelectProps {
  tableCellProps?: TableCellProps;
  valueType?: 'value' | 'currency' | 'percent';
  mapValue?: (value: string) => string;
}

const CellTextInput: FC<TextFieldProps> = ({
  tableCellProps = {},
  valueType,
  value,
  mapValue,
  ...props
}) => {
  return (
    <TableCell {...tableCellProps}>
      <SingleSelect inTable value={value} {...props} />
    </TableCell>
  );
};

export default CellTextInput;
