export const Routes = {
  Root: '/',
  Home: 'home',
  Auth: 'auth',
  SignIn: 'sign-in',
  ResetPasswordEmail: 'reset-password-email',
  ResetPasswordSuccess: 'reset-password-success',
  PasswordReset: 'password-reset',
  ForgotPassword: 'forgot-password',
  Admin: 'admin',
  Customers: 'customers',
  NewCustomer: 'new-customer',
  Customer: 'customer',
  CustomerDetails: 'customer-details',
  LifePlan: 'life-plan',
  LifeRoad: 'life-road',
  LifeRoadAnalytics: 'life-road-analytics',
  Estg: 'estg',
  HousePayment: 'house-payment',
  Planner: 'planner',
  BSC: 'bsc',
  KLV: 'klv',
  LifeInsurance: 'life-insurance',
  Finance: 'finance',
  FinanceInfo: 'finance-info',
  Funding: 'funding',
  Savings: 'savings',
  Calculator: 'calculator',
  Diagram: 'diagram',
  Children: 'children',
  Concept: 'concept',
  Test: 'test',
  Household: 'household',
  HouseholdCalculator: 'household-calculator',
  HouseholdCompare: 'household-compare',
};

export const CompanyRoute = 'https://softverysolutions.com';
