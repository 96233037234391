import { Box, Stack, Typography } from '@mui/material';
import Section from '@/components/ui/Section';
import Image from '@/components/ui/Image';
import { useTranslation } from 'react-i18next';
import { SXStyleSheet } from '@/utils';
import KLVTable from './KVLTable';
import KLVSmallTable from './KLVSmallTable';

const KLVView = () => {
  const { t } = useTranslation();
  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <KLVTable />
      </Section>
      <Section>
        <KLVSmallTable />
      </Section>
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:klv.sectionGraph.title')}
          </Typography>
          <Stack flex={1} alignItems="center" justifyContent="center">
            <Image image="KLVGraph" sx={style.image} />
          </Stack>
        </Stack>
      </Section>
      <Section>
        <Stack gap="19px">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              {t('customer:bsc.section5.title')}
            </Typography>
            <Box
              component="a"
              href="https://www.stern.de/wirtschaft/geld/geldanlage--so-legt-deutschlands-oberster-banker-sein-geld-an-8673640.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image image="SternLogo" />
            </Box>
          </Stack>
          <Typography variant="body1">
            {t('customer:bsc.section5.text')}
          </Typography>
        </Stack>
      </Section>
      <Stack direction="row" gap={1}>
        <Stack flex={2}>
          <Section>
            <Stack pb="29px" gap={2}>
              <Typography variant="subtitle2">
                {t('customer:bsc.section6.title')}
              </Typography>
              <Typography variant="body1">
                {t('customer:bsc.section6.subtitle')}
              </Typography>
            </Stack>
            <Box sx={style.pieWrap}>
              <Image image="AnalyticsBarChart" sx={style.imageBar} />
            </Box>
          </Section>
        </Stack>
        <Stack flex={1}>
          <Section>
            <Stack pb="29px" gap={2}>
              <Typography variant="subtitle2">
                {t('customer:bsc.section7.title')}
              </Typography>
              <Typography variant="body1">
                {t('customer:bsc.section7.subtitle')}
              </Typography>
            </Stack>
            <Box sx={style.pieWrap}>
              <Image image="AnalyticsPieChart" sx={style.imagePie} />
            </Box>
          </Section>
        </Stack>
      </Stack>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  chart: {
    '& .MuiChartsLegend-mark': {
      rx: '4px',
    },
    '& .MuiChartsAxis-tick': {
      stroke: 'transparent !important',
    },
    '& .MuiBarLabel-root': {
      fontSize: '16px',
      fill: '#FFFFFF',
      fontWeight: 600,
    },
  },
  image: {
    width: '100%',
  },
  pieWrap: {
    width: '100%',
    bgcolor: 'white',
    borderRadius: '8px',
  },
  imagePie: {
    margin: '0 auto',
    display: 'block',
    maxWidth: '400px',
  },
  imageBar: {
    margin: '0 auto',
    display: 'block',
  },
  barChartContainer: {
    backgroundColor: 'background.paper',
    borderRadius: 2,
    padding: 6,
    paddingBottom: 2,
  },
});

export default KLVView;
