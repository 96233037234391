import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Stack } from '@mui/material';

const data = [
  {
    name: 'Bausparvertrag',
    deposit: 9600,
    profit: 1587,
  },
  {
    name: 'ETF-Police',
    deposit: 9600,
    profit: 11337,
  },
];

const CustomizedLabel = (props: any) => {
  const { x, y, width, height, value } = props;
  return (
    <text
      x={x + width / 2}
      y={y + height / 2}
      textAnchor="middle"
      fontSize={16}
      fontWeight="bold"
      fill="#FFFFFF"
      dy={-10}
    >
      {value} €
    </text>
  );
};

const CustomizedTitleLabel = (props: any) => {
  const { x, width, value } = props;
  return (
    <text
      x={x + width / 2}
      y={20}
      textAnchor="middle"
      fontSize={16}
      fontWeight="bold"
      fill="#000"
    >
      {value}
    </text>
  );
};

const BSCChart = () => {
  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data}
          margin={{ top: 40, right: 20, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={false} />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="bottom" height={36} />
          <Bar
            dataKey="name"
            fill="transparent"
            label={<CustomizedTitleLabel />}
          />
          <Bar
            dataKey="deposit"
            fill="#85D7F5"
            label={<CustomizedLabel />}
            name="Einzahlung"
          />
          <Bar
            dataKey="profit"
            fill="#00ABEA"
            label={<CustomizedLabel />}
            name="Gewinn"
          />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default BSCChart;
