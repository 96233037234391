import Section from '@/components/ui/Section';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import CalculatorPieChart from './CalculatorPieChart';
import { Customer } from '@/types';
import CellTextField from '@/components/formControl/CellTextField';
import { useCallback } from 'react';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: 4,
    flexWrap: 'nowrap',
  },
  tableWrapper: {
    borderRadius: '8px',
    overflow: 'hidden',
    flex: '1 1 50%',
    minWidth: '320px',
  },
  chartWrapper: {
    flex: '1 1 50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '8px',
  },
  tableCellLeft: (highlight: boolean, index: number) => ({
    backgroundColor: highlight
      ? '#F59418'
      : index % 2 === 0
        ? '#1AA0B0'
        : '#1EB2C3',
    color: '#fff',
    fontWeight: 'bold',
    width: '50%',
  }),
  tableCellRight: (hasInput: boolean, index: number) => ({
    backgroundColor: index % 2 === 0 ? '#E9F4FB' : '#FFFFFF',
    fontWeight: 'normal',
    textAlign: 'start',
    position: 'relative',
    transition: 'border 0.2s ease-in-out',
    '&:hover': hasInput ? { border: '2px solid black' } : {},
    '&:focus-within': hasInput ? { border: '2px solid black' } : {},
  }),
  tableRow: {
    height: '72px',
  },
};

interface CalculatorPieSectionProps {
  data: Partial<Customer> & {
    arbeitnehmerAnteil: number;
    arbeitgeberAnteil: number;
    sozialSteuerersparnis: number;
    deinNettoaufwand: string;
  };
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurUpdate: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CalculatorPieSection: React.FC<CalculatorPieSectionProps> = ({
  data,
  handleInputChange,
  handleBlurUpdate,
}) => {
  const { t } = useTranslation();

  const renderTableRows = useCallback(() => {
    const tableData = [
      {
        label: t('customer:calculator.table.totalPayment'),
        fieldName: 'bav_total_deposit',
        valueEndAdornment: '€',
        endAdornment: '€',
      },
      {
        label: t('customer:calculator.table.subsidyPercentage'),
        fieldName: 'bav_subsidy_in_pension_plan_percent',
        valueEndAdornment: '%',
        endAdornment: '%',
      },
      {
        label: t('customer:calculator.table.vlInBav'),
        fieldName: 'bav_is_vl_contributed_to_pension_plan',
        valueEndAdornment: '€',
        endAdornment: '€',
        highlight: true,
      },
      {
        label: t('customer:calculator.table.employeeShare'),
        value: `${data.arbeitnehmerAnteil.toFixed(2)} €`,
      },
      {
        label: t('customer:calculator.table.employerShare'),
        value: `${data.arbeitgeberAnteil.toFixed(2)} €`,
      },
      {
        label: t('customer:calculator.table.netExpense'),
        value: `${data.deinNettoaufwand} €`,
      },
      {
        label: t('customer:calculator.table.interestRate'),
        fieldName: 'bav_interest_rate',
        valueEndAdornment: '%',
        endAdornment: '%',
      },
      {
        label: t('customer:calculator.table.currentCapital'),
        fieldName: 'bav_capital',
        valueEndAdornment: '€',
        endAdornment: '€',
        highlight: true,
      },
    ];

    return tableData.map((row, i) => (
      <TableRow key={i} sx={styles.tableRow}>
        <TableCell sx={styles.tableCellLeft(!!row.highlight, i)}>
          {row.label}
        </TableCell>
        {row.fieldName ? (
          <CellTextField
            fieldName={row.fieldName}
            valueEndAdornment={row.valueEndAdornment}
            endAdornment={row.endAdornment}
            handleInputChange={handleInputChange}
            onBlur={handleBlurUpdate}
            addPadding={false}
          />
        ) : (
          <TableCell align="right" sx={styles.tableCellRight(false, i)}>
            {row.value}
          </TableCell>
        )}
      </TableRow>
    ));
  }, [data, t, handleInputChange]);

  if (!data) return null;

  return (
    <Formik initialValues={data} onSubmit={() => {}}>
      {() => (
        <Form>
          <Section>
            <Box sx={styles.container}>
              <Box sx={styles.tableWrapper}>
                <Table>
                  <TableBody>{renderTableRows()}</TableBody>
                </Table>
              </Box>
              <Box sx={styles.chartWrapper}>
                <CalculatorPieChart
                  data={{
                    arbeitnehmerAnteil: data.arbeitnehmerAnteil,
                    arbeitgeberAnteil: data.arbeitgeberAnteil,
                    sozialSteuerersparnis: data.sozialSteuerersparnis,
                  }}
                />
              </Box>
            </Box>
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default CalculatorPieSection;
