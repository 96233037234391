import {
  BBesGHigherService,
  BBesGMiddleService,
  BBesGSeniorCivilService,
  BBesGSimpleService,
  EmploymentStatus,
} from '@/types';
import { getSelectItemsFromEnum } from '@/utils/system';

export const customerVisibilityKeys = {
  grossNetSalary: [EmploymentStatus.Contributions, EmploymentStatus.MiniJob],
  salaryCivilServant: [],
  income: [
    EmploymentStatus.Contributions,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.PublicSectorEmployee,
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.MiniJob,
    EmploymentStatus.CivilServant,
  ],
  healthInsuranceStatus: [
    EmploymentStatus.Contributions,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.PublicSectorEmployee,
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.MiniJob,
    EmploymentStatus.CivilServant,
  ],
  healthInsuranceProvider: [
    EmploymentStatus.Contributions,
    EmploymentStatus.MiniJob,
  ],
  jobTitle: [
    EmploymentStatus.Contributions,
    EmploymentStatus.MiniJob,
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.CivilServant,
    EmploymentStatus.PublicSectorEmployee,
  ],
  jobTitleBBesG: [],
  legalForm: [
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,

    EmploymentStatus.CivilServant,
  ],
  employeeCount: [
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,

    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.EmpMdPartner,
    EmploymentStatus.CivilServant,
  ],
  revenueAndProfit: [
    EmploymentStatus.SelfEmpNoEmp,
    EmploymentStatus.SelfEmpWithEmp,
    EmploymentStatus.EmpMdPartner,

    EmploymentStatus.CivilServant,
  ],
  pensionAmount: [EmploymentStatus.Disabled, EmploymentStatus.OldAgePensioner],
};

export const BBesGGroups = {
  BBesGSimpleService: getSelectItemsFromEnum(BBesGSimpleService),
  BBesGMiddleService: getSelectItemsFromEnum(BBesGMiddleService),
  BBesGSeniorCivilService: getSelectItemsFromEnum(BBesGSeniorCivilService),
  BBesGHigherService: getSelectItemsFromEnum(BBesGHigherService),
};

export const DateFormat = 'DD.MM.YYYY';
