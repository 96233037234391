import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Image from '@/components/ui/Image';
import ImageBg, { ImageType } from '@/assets/images';
import zIndex from '@mui/material/styles/zIndex';

const buttonData = [
  {
    label: 'Neues Auto & Reparatur',
    position: { top: '15%', left: '52%' },
    image: 'Auto',
  },
  {
    label: 'Regelmäßige Urlaube',
    position: { top: '15%', left: '25%' },
    image: 'Regelmasige',
  },
  {
    label: 'Renovierung & Wohnungseinrichtung',
    position: { top: '45%', left: '16%' },
    image: 'Renovierung',
  },
  {
    label: 'Umzug & Kaution',
    position: { top: '56%', left: '46%' },
    image: 'Umzug',
  },
  {
    label: 'Neue Immobilie erwerben',
    position: { top: '53%', left: '70%' },
    image: 'Immobile',
  },
  {
    label: 'Kapital im Rentenalter',
    position: { top: '65%', left: '90%' },
    image: 'KapitalRoad',
  },
  {
    label: 'Individuelle Ziele',
    position: { top: '86%', left: '50%' },
    image: 'Individuele',
  },
];

const styles = {
  container: {
    position: 'relative',
    width: '950px',
    backgroundImage: `url(${ImageBg.RoadCalculator})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '12px',
    overflow: 'hidden',
  },
  buttonBox: (position: { top: string; left: string }, isActive: boolean) => ({
    position: 'absolute',
    top: position.top,
    left: position.left,
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    opacity: isActive ? 1 : 0.9, // Grayscale effect
    transition: 'opacity 0.3s ease',
  }),
  imageBox: (isActive: boolean) => ({
    overflow: 'hidden',
  }),
  labelText: (isActive: boolean) => ({
    marginTop: '5px',
    padding: '2px 8px',
    fontSize: '12px',
    fontWeight: 'bold',
    background: isActive ? '#FFA500' : '#D3D3D3',
    color: isActive ? 'white' : '#666',
    borderRadius: '12px',
  }),
};

interface RoadBlockAnalyticsProps {
  getActiveButtons: (buttons: Record<string, boolean>) => void;
}

const RoadBlockAnalytics: React.FC<RoadBlockAnalyticsProps> = ({
  getActiveButtons,
}) => {
  const [activeButtons, setActiveButtons] = useState<Record<string, boolean>>(
    buttonData.reduce((acc, item) => ({ ...acc, [item.label]: false }), {}),
  );

  const toggleButton = (label: string) => {
    setActiveButtons((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  useEffect(() => {
    getActiveButtons(activeButtons);
  }, [activeButtons, getActiveButtons]);

  return (
    <Box sx={styles.container}>
      {buttonData.map(({ label, position, image }) => (
        <Box
          key={label}
          onClick={() => toggleButton(label)}
          sx={styles.buttonBox(position, activeButtons[label])}
        >
          <Box sx={styles.imageBox(activeButtons[label])}>
            <Image
              image={image as ImageType}
              style={{
                filter: activeButtons[label] ? 'none' : 'grayscale(100%)',
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default RoadBlockAnalytics;
