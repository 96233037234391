import Section from '@/components/ui/Section';
import { Stack } from '@mui/material';
import React from 'react';
import EstgForm from './EstgSections/EstgForm';
import Image from '@/components/ui/Image';
import EstgSmallTable from './EstgSections/EstgSmallTable';
import EstgChart from './EstgSections/EstgChart';

const EstgPage = () => {
  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Section>
        <EstgForm />
      </Section>
      <Section>
        <Image image="EstgImage" sx={{ width: '100%' }} />
      </Section>
      <Section>
        <EstgSmallTable />
      </Section>
      <EstgChart />
      <Section>
        <EstgSmallTable />
      </Section>
    </Stack>
  );
};

export default EstgPage;
