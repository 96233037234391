import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { split } from 'lodash';
import { useNavigate } from 'react-router-dom';

import Badge from '@/components/ui/Badge';
import Pagination from '@/components/ui/Pagination';
import Table from '@/components/ui/Table';
import CustomerTableRaw from '@/components/ui/CustomerTableRow';
import Header from '@/components/Header';
import CustomerFilter from '@/components/CustomerFilter';
import FormCard from '@/components/ui/FormCard';

import useAuthStore from '@/store/auth';
import useCustomerStore from '@/store/customers';
import { Customer } from '@/types';
import { CustomerTableConfig } from '@/constants/table';
import { getPath, getPathQuery, SXStyleSheet } from '@/utils';
import { Routes } from '@/constants/routes';

const CustomersView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useAuthStore();
  const {
    customers,
    pagination,
    searchCustomers,
    prevPage,
    nextPage,
    fetchCustomers,
  } = useCustomerStore();

  const getTableData = useCallback(async () => {
    fetchCustomers().catch(console.error);
  }, [fetchCustomers]);

  useEffect(() => {
    getTableData().catch();
  }, [getTableData]);

  const handleCustomerClick = (id: number) => {
    navigate(
      getPathQuery(
        getPath(
          '..',
          Routes.Customers,
          Routes.Customer,
          Routes.CustomerDetails,
        ),
        {
          id,
        },
      ),
    );
  };

  const renderTableRow = (item: Customer) => {
    return (
      <CustomerTableRaw
        onCustomerClick={handleCustomerClick}
        data={item as Customer}
        tableConfig={CustomerTableConfig}
        key={item.id}
      />
    );
  };

  const handleCreateCustomer = async () => {
    navigate(
      getPath(
        '..',
        Routes.Customers,
        Routes.NewCustomer,
        Routes.CustomerDetails,
      ),
    );
  };

  const renderTableTitle = useCallback(() => {
    return (
      <Stack direction="row" alignItems="center" gap={2} pb={6}>
        <Typography variant="subtitle1">
          {t('customer:home.customerTable.title')}
        </Typography>
        <Badge
          text={t('customer:home.customerTable.userCount', {
            count: pagination?.total,
          })}
        />
      </Stack>
    );
  }, [pagination?.total, t]);

  return (
    <Stack sx={style.container}>
      <Header
        title={t('admin:home.title', { name: split(user?.name, ' ')[0] })}
      />
      <Stack sx={style.contentWrapper}>
        <CustomerFilter
          onSearchValueChange={searchCustomers}
          onAddClick={handleCreateCustomer}
        />
        <FormCard flex={1} overflow="hidden" pb={4}>
          <Table
            trKey="customer:home.tableLabels"
            data={customers || []}
            renderRow={renderTableRow}
            renderTableTitle={renderTableTitle}
            headerItems={CustomerTableConfig}
          />
          <Pagination
            page={pagination?.current_page || 0}
            total={pagination?.last_page || 0}
            nextPage={nextPage}
            prevPage={prevPage}
          />
        </FormCard>
      </Stack>
    </Stack>
  );
};

const style = SXStyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'background.screen',
  },
  tableContainer: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: 'background.main',
  },
  contentWrapper: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: 'background.card',
    mx: 6,
    mb: 6,
    borderRadius: 3,
    p: 6,
    gap: 6,
  },
});

export default CustomersView;
