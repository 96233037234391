import { useState, useEffect } from 'react';
import TableGenerator, { RowCell } from '@/components/ui/TableGenerator';
import {
  RoadSectionAnalyticsTableHeader,
  RoadSectionAnalyticsTableRows,
} from '@/constants/tables';

import { Stack } from '@mui/material';

const LifeRoadAnalyticsTable = ({
  selectedValues,
  handleTotalSum,
}: {
  selectedValues: Record<string, string>;
  handleTotalSum: (val: number) => void;
}) => {
  // Define state to store sum of all painted cells
  const [totalSum, setTotalSum] = useState(0);

  // Define which column should be colored based on selection
  const columnMapping: Record<string, number> = {
    Preisbewusst: 2, // 3rd cell (index 2)
    'Preis/Leistung': 3, // 4th cell (index 3)
    Hochwertig: 4, // 5th cell (index 4)
  };

  // Define color settings
  const colorSettings = {
    color: '#475467',
    bgColor: '#E0FFE6',
  };

  // Function to safely extract numeric values from strings
  const extractNumericValue = (value: string | number): number => {
    if (typeof value === 'string') {
      const cleanedValue = value.replace(/[^\d,]/g, '').replace(',', '.');
      return parseFloat(cleanedValue) || 0;
    }
    return typeof value === 'number' ? value : 0;
  };

  // Track sum of painted values
  let sum = 0;

  // Map over table rows and apply modifications for ALL categories
  const tableValues: RowCell[][] = RoadSectionAnalyticsTableRows.map(
    ({ row, relatesTo }) => {
      if (relatesTo && selectedValues[relatesTo]) {
        const category = selectedValues[relatesTo];

        // Special handling for "Individuelle Ziele"
        if (relatesTo === 'Individuelle Ziele') {
          return row.map((cell, index) => {
            if (index === 1) {
              return { ...cell, value: 'Ja', color: '#1AA0B0' }; // Paint 2nd cell
            } else if (index === 2 && category) {
              const value = extractNumericValue(category);
              sum += value; // Add to sum
              return {
                ...cell,
                value: `${category} €`,
                ...colorSettings,
                colspan: 3,
              }; // Paint 3rd cell
            }
            return cell;
          });
        }

        // Handle other categories normally
        const columnIndex = columnMapping[category]; // Determine which column to color

        return row.map((cell, index) => {
          if (index === 1) {
            return { ...cell, value: 'Ja', color: '#1AA0B0' }; // Modify 2nd cell (index 1)
          } else if (index === columnIndex) {
            const value = extractNumericValue(cell.value);
            sum += value; // Add to sum
            return { ...cell, ...colorSettings }; // Apply color based on selection
          }
          return cell;
        });
      }
      return row;
    },
  );

  // Update total sum state whenever table values change
  useEffect(() => {
    setTotalSum(sum);
    handleTotalSum(sum);
  }, [selectedValues, sum]);

  return (
    <Stack gap={6}>
      <TableGenerator
        head={RoadSectionAnalyticsTableHeader}
        values={tableValues}
      />
    </Stack>
  );
};

export default LifeRoadAnalyticsTable;
