import { FC, useRef } from 'react';
import { Stack, Table, TableCell, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';
import style from './style';
import { Formik, FormikProps } from 'formik';

import { Insurance } from '@/types';

import SavingCompareRow from './SavingCompareRow';

export interface SavingsCompareProps {
  insurance: Insurance;
  onUpdate: (insurance: Partial<Insurance>) => void;
}

const SavingsCompare: FC<SavingsCompareProps> = ({ insurance, onUpdate }) => {
  const formRef = useRef<FormikProps<Insurance>>(null);
  const { t } = useTranslation();

  return (
    <Formik innerRef={formRef} initialValues={insurance} onSubmit={onUpdate}>
      <Stack>
        <Table sx={style.table}>
          <TableHead>
            <TableCell>{t('customer:savings.table.provider')}</TableCell>
            <TableCell>{t('customer:savings.table.toc')}</TableCell>
            <TableCell>{t('customer:savings.table.return')}</TableCell>
            <TableCell>{t('customer:savings.table.duration')}</TableCell>
            <TableCell>{t('customer:savings.table.currentBalance')}</TableCell>
            <TableCell>{t('customer:savings.table.einzahlung')}</TableCell>
            <TableCell colSpan={2}>
              {t('customer:savings.table.fvcp')}
            </TableCell>
          </TableHead>
          <SavingCompareRow />
        </Table>
      </Stack>
    </Formik>
  );
};

export default SavingsCompare;
