import { Table } from '@mui/material';

import InlineTableHeader from './InlineTableHeader';
import InlineTableRow from '@/components/ui/InlineTable/InlineTableRow';
import { useMemo } from 'react';
import { InlineTableProps } from '@/components/ui/InlineTable/types';
import { keys, map } from 'lodash';
import { useTranslation } from 'react-i18next';

import style from './style';

const InlineTable = <T extends object>({
  mapKeysToTitle,
  customTableCell,
  data,
  variant = 'primary',
}: InlineTableProps<T>) => {
  const { t } = useTranslation();

  const tableHeaderItems = useMemo(() => {
    return map(keys(data), (key) => t(mapKeysToTitle[key as keyof T]));
  }, [data, mapKeysToTitle, t]);

  return (
    <Table sx={[style.table, variant === 'accent' && style.tableAccent]}>
      <InlineTableHeader headerTitles={tableHeaderItems} />
      <InlineTableRow data={data} customTableCell={customTableCell} />
    </Table>
  );
};

export default InlineTable;
