export enum MaritalStatus {
  Single = 'single',
  Married = 'married',
  Divorced = 'divorced',
  Widowed = 'widowed',
  Cohabiting = 'cohabiting',
  SingleParent = 'single_parent',
}

export enum LegalForm {
  IndividualEnterprise = 'individual_enterprise',
  LimitedCompany = 'limited_company',
  GBR = 'gbr',
  GeneralPartnership = 'general_partnership',
  GMBH = 'gmbh',
  UG = 'ug',
  AG = 'ag',
}

export enum YesNo {
  No = 'no',
  Yes = 'yes',
}

export enum PensionInsuranceStatus {
  SubjectStatutory = 'subject_statutory',
  VoluntarilyInsured = 'voluntarily_insured',
  NotLegal = 'not_legal',
}

export enum BBesGSimpleService {
  A03 = 'A 03',
  A04 = 'A 04',
  A05 = 'A 05',
  A06E = 'A 06e',
}

export enum BBesGMiddleService {
  A05M = 'A 05m',
  A06 = 'A 06',
  A07 = 'A 07',
  A08 = 'A 08',
  A09 = 'A 09',
}

export enum BBesGSeniorCivilService {
  A09G = 'A 09g',
  A10 = 'A 10',
  A11 = 'A 11',
  A12 = 'A 12',
  A13 = 'A 13',
}

export enum BBesGHigherService {
  A13H = 'A 13h',
  A14 = 'A 14',
  A15 = 'A 15',
  A16 = 'A 16',
  B01 = 'B 01',
  B02 = 'B 02',
  B03 = 'B 03',
  B04 = 'B 04',
  B05 = 'B 05',
  B06 = 'B 06',
  B07 = 'B 07',
  B08 = 'B 08',
  B09 = 'B 09',
  B10 = 'B 10',
  B11 = 'B 11',
  W01 = 'W 01',
  W02 = 'W 02',
  W03 = 'W 03',
}

export enum SalaryGroup {
  A = 'A',
  B = 'B',
  R = 'R',
  W = 'W',
  C = 'C',
  H = 'H',
}

export enum ExperienceLevel {
  One = 'one',
  Two = 'two',
  Three = 'three',
  Four = 'four',
  Five = 'five',
  Six = 'six',
  Seven = 'seven',
  Eight = 'eight',
  Nine = 'nine',
  Ten = 'ten',
  Eleven = 'eleven',
  Twelve = 'twelve',
}

export enum EmploymentStatus {
  Contributions = 'ss_contributions',
  SelfEmpNoEmp = 'self_emp_no_emp',
  SelfEmpWithEmp = 'self_emp_with_emp',
  EmpMdPartner = 'emp_md_partner',
  MiniJob = 'mini_job',
  JobSeeker = 'job_seeker',
  HouseWifeHusband = 'housewife_husband',
  Disabled = 'disabled',
  OldAgePensioner = 'old_age_pensioner',
  CivilServant = 'civil_servant',
  PublicSectorEmployee = 'public_sector_employee',
}

export enum HealthInsuranceStatus {
  GKV = 'GKV',
  PKV = 'PKV',
}

export enum ChildrenCount {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
  Eleven = '11',
  Twelve = '12',
}

export interface Child {
  name: string;
  year_of_birth: string;
}

export interface Customer {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  phone: string;
  address: string;
  postal_code: string;
  city: string;
  company_purpose: string;
  employee_count: string;
  partner_id: number | null;
  revenue: number;
  profit: number;
  pension_amount: number;
  job_title: string;
  health_insurance_provider: string;
  gross_salary: number;
  net_salary: number;
  additional_income_type: string | null;
  additional_income_amount: number | null;
  child_benefit_eligible_children: number;
  child_benefit_non_eligible_children: number;
  owns_property: boolean;
  is_smoker: boolean;
  highest_education: string;
  rent_amount: number;
  created_at: string;
  updated_at: string;
  marital_status: MaritalStatus;
  legal_form: LegalForm;
  pension_insurance_status: PensionInsuranceStatus;
  salary_group: SalaryGroup;
  experience_level: ExperienceLevel;
  employment_status: EmploymentStatus;
  health_insurance_status: HealthInsuranceStatus;
  children: Child[] | Child;
  partner: Customer | null;
  bav_total_deposit?: number;
  bav_subsidy_in_pension_plan_percent?: number;
  bav_is_vl_contributed_to_pension_plan?: number;
  bav_interest_rate?: number;
  bav_capital?: number;
  savings_calculation?: {
    current_age?: number;
    target_age?: number;
    savings_rate?: number;
    roi?: number;
    customer_id?: number;
    fundings_sum?: number;
    adjustment?: any[];
  };
  // @todo(KAN-85): not found in swagger but exists in ui
  height?: string;
  weight?: string;
}

export type CustomerFormType = 'partner' | 'customer';

export type CustomerForm = Record<CustomerFormType, Partial<Customer> | null>;

export interface CustomerFormProps {
  formKey: CustomerFormType;
}

export interface Insurance {
  category: 'property_liability' | 'life_financial';
  type: string;
  provider: string;
  monthly_payment: number;
  annual_payment: number;
  new_provider: string;
  new_monthly_payment: number;
  new_annual_payment: number;
  info: string;
  roi: number;
  new_roi: number;
  expense: number;
  start_year: string;
  duration_in_years: number;
  credit_balance: number;
  future_values: number[];
  id: number;
}

export interface Funding {
  comment: string;
  description: string;
  id: number;
  purpose: string;
  sum: number;
  type: string;
}

export interface CalculateSavingsRequest {
  current_age: number;
  savings_rate: number;
  target_age: number;
  roi: number;
  fundings_sum: number;
}

export interface CalculateSavingsResponse {
  year: number;
  age: number;
  savings_rate: number;
  cash_subsidy: number;
  annual_payment: number;
  annual_sum: number;
  withdrawal: number;
  payment_total: number;
  capital: number;
  subsidy_tax_free: number;
  return_of_investment: number;
}


export interface ProductsTable {
  id:number;
  product: string;
  provider: string;
  contribution: number;
  funding_immediate: number;
  downstream: number;
}

export type InsuranceCreate = Omit<Insurance, 'future_values'>;
