import { Stack, TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import CellTextField from '@/components/formControl/CellTextField';
import IconButton from '@/components/ui/IconButton';
import { FC } from 'react';
import { LifeInsuranceList } from '@/constants/insurances';
import { getFormKey } from '@/utils/system';
import { Insurance } from '@/types';
import useFormField from '@/hooks/useFormField';
import { FieldArrayRenderProps } from 'formik';

export interface LifeInsuranceRowProps {
  formKey: string;
  index: number;
  onUpdate?: (insurance: Partial<Insurance>) => void;
  onDelete: (index: number) => void; // remove  row on the backend
  remove: FieldArrayRenderProps['remove']; // remove empty row locally
}

const LifeInsuranceRow: FC<LifeInsuranceRowProps> = ({
  formKey,
  onDelete,
  onUpdate,
  remove,
  index,
}) => {
  const { value } = useFormField<Partial<Insurance>>(formKey, {});



  const handleUpdate = () => {
    onUpdate?.(value);
  };

  const handleDelete = () => {
    if (value.id) {
      onDelete(value.id);
    } else {
      remove(index);
    }
  };

  return (
    <TableRow>
      <CellSelectField
        fieldName={getFormKey(formKey, 'type')}
        items={LifeInsuranceList}
        onSelect={handleUpdate}
      />
      <CellTextField
        onBlur={handleUpdate}
        fieldName={getFormKey(formKey, 'provider')}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'monthly_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'annual_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        onBlur={handleUpdate}
        fieldName={getFormKey(formKey, 'info')}
      />
      <TableCell>
        <Stack
          onClick={handleDelete}
          alignItems="center"
          justifyContent="center"
          p="6px"
          sx={{ cursor: 'pointer' }}
        >
          <IconButton icon="Delete" variant="primary" />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default LifeInsuranceRow;
