import { SideBarItemData } from '@/components/ui/SideBarItem';
import { Routes } from '@/constants/routes';
import { NavigationBarItemType } from '@/components/ui/NavigationBar/types';

export const SideBarMaxLetterLength = 22;

export const SideBarAdminNavigation: SideBarItemData[] = [
  {
    icon: 'Home',
    iconActive: 'HomeActive',
    path: Routes.Admin,
  },
  {
    icon: 'Users',
    iconActive: 'UsersActive',
    path: Routes.Customers,
  },
];

export const SideBarConsultantNavigation: SideBarItemData[] = [
  {
    icon: 'Users',
    path: Routes.Customers,
  },
];

// @todo(KAN-92):connect with paths
export const CustomerDetailsSideBar: SideBarItemData[] = [
  {
    icon: 'AccountCircle',
    path: Routes.CustomerDetails,
  },
  {
    icon: 'Money',
    path: Routes.Finance,
  },
  {
    icon: 'Planner',
    path: Routes.Planner,
  },
  {
    icon: 'TodoList',
    path: Routes.LifePlan,
  },
  {
    icon: 'Idea',
    path: Routes.Concept,
  },
  {
    icon: 'Wallet',
    path: '/',
  },
  {
    icon: 'Chart',
    path: Routes.Household,
  },
];

export const LifePlanSideBar: NavigationBarItemType[] = [
  {
    label: 'Straße des Lebens DIN',
    icon: 'Map',
    activeIcon: 'MapActive',
    path: Routes.LifeRoad,
  },
  {
    label: 'Diagramm',
    icon: 'Diagram',
    activeIcon: 'DiagramActive',
    path: Routes.Diagram,
  },
  {
    label: 'Kindersparen',
    icon: 'ID',
    activeIcon: 'ID',
    path: Routes.Children,
  },
  {
    label: 'Hausgeld',
    icon: 'Bill',
    activeIcon: 'BillActive',
    path: Routes.HousePayment,
  },
  {
    label: 'Straße des Lebens',
    icon: 'Navigation',
    activeIcon: 'Navigation',
    path: Routes.LifeRoadAnalytics,
  },
  {
    label: '10a EStG',
    icon: 'Euro',
    activeIcon: 'EuroActive',
    path: Routes.Estg,
  },
];

export const PlannerSideBarItems: NavigationBarItemType[] = [
  {
    label: 'Leben Bestand',
    activeIcon: 'HealthActive',
    icon: 'Health',
    path: Routes.LifeInsurance,
  },
  {
    label: 'Sparvergleich Einzein',
    activeIcon: 'BanknoteActive',
    icon: 'Banknote',
    path: Routes.Savings,
  },
  {
    label: 'Bausparvertrag',
    activeIcon: 'DocumentActive',
    icon: 'Document',
    path: Routes.BSC,
  },
  {
    label: 'bAV Rechner',
    activeIcon: 'Monitor',
    icon: 'Monitor',
    path: Routes.Calculator,
  },
  {
    label: 'KLV',
    activeIcon: 'MoneyBag',
    icon: 'MoneyBag',
    path: Routes.KLV,
  },
];

export const FinancesSideBarItems: NavigationBarItemType[] = [
  {
    label: 'Förderungen',
    icon: 'Finance',
    activeIcon: 'FinanceActive',
    path: Routes.Funding,
  },
  {
    label: 'Sach',
    icon: 'File',
    activeIcon: 'FileActive',
    path: Routes.FinanceInfo,
  },
];

export const HouseholdSideBarItems: NavigationBarItemType[] = [
  {
    label: 'Haushaltsrechner',
    activeIcon: 'HealthActive',
    icon: 'Health',
    path: Routes.HouseholdCalculator,
  },
  {
    label: 'Depot vs Flex',
    activeIcon: 'BanknoteActive',
    icon: 'Banknote',
    path: Routes.HouseholdCompare,
  },
];

export const ConceptSideBarItems: NavigationBarItemType[] = [
  {
    label: 'Konzept',
    icon: 'Idea',
    activeIcon: 'IdeaActive',
    path: Routes.Test,
  },
];
