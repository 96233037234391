import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { map } from 'lodash';

import { SingleSelectItem } from '@/components/ui/SingleSelect';

import style from './style';

export interface RadioSelectProps {
  value: string | boolean;
  options: SingleSelectItem[];
  label?: string;
  subtitle?: string;
  error?: string | null;
  onSelect?: (value: string) => void;
  display?: 'block' | 'inline';
}

const RadioSelect: FC<RadioSelectProps> = ({
  value,
  options,
  label,
  subtitle,
  error,
  onSelect,
  display = 'block',
}) => {
  const renderRadioGroupItem = (item: SingleSelectItem) => {
    return (
      <FormControlLabel
        key={item.value}
        control={<Radio />}
        label={item.label}
        value={item.value}
        sx={style.select}
      />
    );
  };

  const handleChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    onSelect?.(value);
  };

  return (
    <Stack
      style={style.container}
      direction={display === 'inline' ? 'row' : 'column'}
    >
      <Stack
        flexBasis={display === 'inline' ? 254 : ''}
        width={display === 'block' ? 254 : ''}
        gap={2}
      >
        {!!label && (
          <Typography
            variant="body1"
            sx={style.label}
            color={error ? 'error.main' : 'text.secondary'}
          >
            {label}
          </Typography>
        )}
        {!!subtitle && (
          <Typography
            variant="body2"
            color={error ? 'error.main' : 'text.primary'}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack flex={1} justifyContent={'flex-end'}>
        <FormControl fullWidth>
          <RadioGroup
            value={value}
            onChange={handleChange}
            row
            sx={{ width: '100%', display: 'flex', gap: '8px' }}
          >
            {map(options, renderRadioGroupItem)}
          </RadioGroup>
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default RadioSelect;
