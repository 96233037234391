import Section from '@/components/ui/Section';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from 'recharts';

interface CapitalDifferenceChartProps {
  gesamtkapitalBAV: number;
  nettokapitalBAV: number;
  gesamtkapitalETF: number;
  nettokapitalETF: number;
}

const CapitalDifferenceChart: React.FC<CapitalDifferenceChartProps> = ({
  gesamtkapitalBAV,
  nettokapitalBAV,
  gesamtkapitalETF,
  nettokapitalETF,
}) => {
  const { t } = useTranslation();

  const data = [
    { name: 'Gesamtkapital bAV', value: gesamtkapitalBAV },
    { name: 'Nettokapital bAV', value: nettokapitalBAV },
    { name: 'Gesamtkapital ETF', value: gesamtkapitalETF },
    { name: 'Nettokapital ETF', value: nettokapitalETF },
  ];

  return (
    <Section>
      <Stack gap="19px">
        <Typography variant="subtitle2">
          {t('customer:calculator.section2.title')}
        </Typography>
        <Box padding={3} bgcolor={'#fff'} borderRadius={'8px'}>
          <ResponsiveContainer width="100%" height={500}>
            <BarChart
              data={data}
              margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
              style={{ backgroundColor: '#fff' }}
            >
              <CartesianGrid
                strokeDasharray="none"
                horizontal={true}
                vertical={false}
                stroke="#dcdcdc"
              />
              <XAxis
                dataKey="name"
                angle={0}
                textAnchor="middle"
                interval={0}
                tick={{ fill: '#000' }}
                tickMargin={15}
                tickLine={false}
              />
              <YAxis
                tickFormatter={(value) => `${value.toLocaleString()}€`}
                ticks={[0, 10000, 20000, 30000, 40000]}
                reversed={true}
                axisLine={false}
                tick={{ fill: '#000' }}
                tickLine={false}
                tickMargin={10}
              />
              <Tooltip formatter={(value) => `${value.toLocaleString()}€`} />
              <Bar dataKey="value">
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index % 2 === 0 ? '#F59418' : '#85D7F5'}
                  />
                ))}
                <LabelList
                  dataKey="value"
                  position="insideBottom"
                  fontSize={14}
                  content={(props: any) => {
                    const { index, x, y, value, width } = props;
                    const color = index % 2 === 0 ? '#fff' : '#00546F';
                    const adjustedY = y - 26;
                    return (
                      <text
                        fill={color}
                        x={x + width / 2}
                        y={adjustedY}
                        textAnchor="middle"
                        fontSize={16}
                        fontWeight={500}
                      >
                        {value}
                      </text>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Stack>
    </Section>
  );
};

export default CapitalDifferenceChart;
