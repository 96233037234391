import Header from '@/components/Header';
import ProductTable from '@/components/tables/Product/ProductTable';
import Section from '@/components/ui/Section';
import { Routes } from '@/constants/routes';
import { Stack, Typography } from '@mui/material';
import { some } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';

const ConceptTable = () => {
  const { t } = useTranslation();
  const matches = useMatches();

  const isNewCustomer = useMemo(() => {
    return some(matches, (match) => {
      return match.pathname.includes(Routes.NewCustomer);
    });
  }, [matches]);
  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <Header
        title={t(
          !isNewCustomer
            ? 'customer:customerDetails.title'
            : 'customer:newCustomer.title',
        )}
        subtitle={t('customer:concept.subtitle')}
      />
      <Section>
        <Stack gap="19px">
          <Typography variant="subtitle2">
            {t('customer:financeInfo.section1.title')}
          </Typography>
          <ProductTable />
        </Stack>
      </Section>
    </Stack>
  );
};

export default ConceptTable;
