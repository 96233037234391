import currency from 'currency.js';
import Decimal from 'decimal.js';
import { SingleSelectItem } from '@/components/ui/SingleSelect';

export const euro = (value: string | number) =>
  currency(value, {
    symbol: '€',
    separator: '.',
    decimal: ',',
    pattern: '# !',
  });

export const pmt = (
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  type = 0,
) => {
  if (rate === 0) {
    return -(pv + fv) / nper;
  }

  const q = Math.pow(1 + rate, nper);
  return -(rate * (pv * q + fv)) / ((type ? 1 + rate : 1) * (q - 1));
};

export const futureValue = (
  rate: number,
  numberPeriods: number,
  paymentPerPeriod: number,
  presentValue: number = 0,
  type = 0,
): Decimal => {
  const decRate = new Decimal(rate);
  const decNumberPeriods = new Decimal(numberPeriods);
  const decPaymentPerPeriod = new Decimal(paymentPerPeriod);
  const decPresentValue = new Decimal(presentValue);
  const decType = new Decimal(type);

  if (decRate.equals(0)) {
    return decPresentValue.add(decPaymentPerPeriod.mul(decNumberPeriods));
  }

  const q = new Decimal(1).add(decRate).pow(decNumberPeriods);

  const annuityFactor = decPaymentPerPeriod
    .mul(decType.equals(1) ? decRate.add(1) : new Decimal(1))
    .mul(q.sub(1))
    .div(decRate);

  return new Decimal(0).sub(decPresentValue.mul(q)).sub(annuityFactor);
};

export const getRange = (
  start: number,
  end: number,
  step: number,
): SingleSelectItem[] => {
  let items: SingleSelectItem[] = [];

  for (let i = start; i <= end; i += step) {
    items.push({
      label: `${i.toLocaleString('de-DE')} %`,
      value: i.toString(),
    });
  }

  return items;
};
