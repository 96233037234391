import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { euro } from '@/utils/math';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '12px',
    overflow: 'hidden',
    width: '100%',
    height: '72px',
  },
  leftBox: {
    backgroundColor: '#1EB2C3',
    color: '#fff',
    fontWeight: 'bold',
    padding: '16px',
    flex: '1 1 50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rightBox: {
    backgroundColor: '#fff',
    color: '#333',
    fontWeight: 'bold',
    padding: '16px',
    flex: '1 1 50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
};

const EstgSmallTable = () => {
  const { t } = useTranslation();
  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftBox}>
        <Typography>{t('customer:estg.table.contribution')}</Typography>
      </Box>
      <Box sx={styles.rightBox}>
        <Typography>{euro(41031.6).format()}</Typography>
      </Box>
    </Box>
  );
};

export default EstgSmallTable;
