import { useEffect, useState } from 'react';
import { MenuItem, Select, Typography, Box, TextField } from '@mui/material';
import Icon from '@/components/ui/Icon';
import RoadBlockAnalytics from './RoadBlockAnalytics';

const dummyOptions = [
  {
    label: 'Neues Auto & Reparatur',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
  {
    label: 'Regelmäßige Urlaube',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
  {
    label: 'Renovierung & Wohnungseinrichtung',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
  {
    label: 'Umzug & Kaution',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
  {
    label: 'Neue Immobilie erwerben',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
  {
    label: 'Kapital im Rentenalter',
    options: ['Preisbewusst', 'Preis/Leistung', 'Hochwertig'],
  },
];

const initialSelectedValues = dummyOptions.reduce(
  (acc, item) => ({ ...acc, [item.label]: '' }),
  { 'Individuelle Ziele': '' },
);

const styles = {
  // containerOuter: { width: '100%' },

  container: {
    display: 'flex',
    alignItems: 'stretch',
    gap: 4,
    width: '100%',
    justifyContent: 'center',
  },
  dropdownList: {
    maxWidth: '251px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  dropdownArrow: { width: '20px', transition: 'transform 0.3s ease' },
  selectField: (hasValue: boolean, disabled: boolean) => ({
    backgroundColor: disabled ? '#f5f5f5' : hasValue ? 'white' : 'transparent',
    '& .MuiOutlinedInput-root': {
      backgroundColor: disabled
        ? '#f5f5f5'
        : hasValue
          ? 'white'
          : 'transparent',
    },
  }),
  textField: (hasValue: boolean, disabled: boolean) => ({
    width: '100%',
    backgroundColor: disabled ? '#f5f5f5' : hasValue ? 'white' : '#f5f5f5',
    '& input': { padding: '10px' },
  }),
};

interface LifeRoadAnalyticsOptionsProps {
  onSelectedValuesChange: (values: Record<string, string>) => void;
  activeButtons: Record<string, boolean>;
  onActiveButtonsChange: (buttons: Record<string, boolean>) => void;
}

const LifeRoadAnalyticsOptions: React.FC<LifeRoadAnalyticsOptionsProps> = ({
  onSelectedValuesChange,
  activeButtons,
  onActiveButtonsChange,
}) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    initialSelectedValues,
  );

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  // Watch for activeButtons changes
  useEffect(() => {
    setSelectedValues((prev) => {
      const newValues = { ...prev };

      Object.entries(activeButtons).forEach(([label, isActive]) => {
        if (label !== 'Individuelle Ziele') {
          if (isActive && !prev[label]) {
            newValues[label] = 'Preisbewusst';
          } else if (!isActive) {
            newValues[label] = ''; // Reset if deactivated
          }
        }
      });

      if (!activeButtons['Individuelle Ziele']) {
        newValues['Individuelle Ziele'] = ''; // Reset "Individuelle Ziele" when deactivated
      }

      return newValues;
    });
  }, [activeButtons]);

  useEffect(() => {
    onSelectedValuesChange(selectedValues);
  }, [selectedValues, onSelectedValuesChange]);

  const handleSelectChange = (label: string, value: string) => {
    setSelectedValues((prev) => ({ ...prev, [label]: value }));
  };

  const handleOpen = (index: number) => {
    setOpenIndex(index);
  };

  const handleClose = () => {
    setOpenIndex(null);
  };

  const IconComponent = (props: any) => {
    const rotate =
      openIndex === props.index ? 'rotate(180deg)' : 'rotate(0deg)';
    return (
      <Icon
        icon="DropdownArrow"
        sx={{ ...styles.dropdownArrow, transform: rotate }}
        {...props}
      />
    );
  };

  return (
    <Box sx={styles.container}>
      <RoadBlockAnalytics getActiveButtons={onActiveButtonsChange} />

      <Box sx={styles.dropdownList}>
        {dummyOptions.map((item, index) => {
          const isDisabled = !activeButtons[item.label];

          return (
            <Box key={item.label}>
              <Typography
                variant="body2"
                fontWeight="bold"
                marginBottom={'8px'}
              >
                {item.label}
              </Typography>

              {/* Select Dropdown */}
              <Select
                fullWidth
                value={selectedValues[item.label]}
                onChange={(e) => handleSelectChange(item.label, e.target.value)}
                displayEmpty
                disabled={isDisabled} // Disable if false
                IconComponent={(props) => (
                  <IconComponent {...props} index={index} />
                )}
                onOpen={() => handleOpen(index)}
                onClose={handleClose}
                sx={styles.selectField(
                  !!selectedValues[item.label],
                  isDisabled,
                )}
              >
                {isDisabled ? (
                  <MenuItem value="">Auswählen</MenuItem> // Only show placeholder if disabled
                ) : (
                  item.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
          );
        })}

        {/* Individuelle Ziele Input */}
        <Box>
          <Typography variant="body2" fontWeight="bold" marginBottom={'8px'}>
            Individuelle Ziele
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            fullWidth
            disabled={!activeButtons['Individuelle Ziele']}
            value={selectedValues['Individuelle Ziele']}
            onChange={(e) => {
              const value = e.target.value;

              // Allow only positive numbers, prevent changing value if 0 is entered
              if (
                value === '' ||
                (Number(value) > 0 && !isNaN(Number(value)))
              ) {
                handleSelectChange('Individuelle Ziele', value);
              }
            }}
            sx={styles.textField(
              !!selectedValues['Individuelle Ziele'],
              !activeButtons['Individuelle Ziele'],
            )}
            placeholder="Eingeben"
            slotProps={{
              input: { inputMode: 'numeric', 'aria-valuemin': 1 },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LifeRoadAnalyticsOptions;
