import { SXStyleSheet } from '@/utils';
import {
  tableCellClasses,
  tableHeadClasses,
  tableRowClasses,
} from '@mui/material';

const style = SXStyleSheet.create({
  table: {
    borderRadius: 2,
    overflow: 'hidden',
    [`& .${tableHeadClasses.root}`]: {
      [`& .${tableRowClasses.root}`]: {
        '&:nth-child(even)': {
          [`& .${tableCellClasses.root}`]: {
            backgroundColor: '#079DAF',
          },
        },
        [`& .${tableCellClasses.root}`]: {
          fontWeight: 700,
          fontSize: 16,
          height: 44,
          p: 0,
          px: '24px',
          color: 'primary.contrastText',
          backgroundColor: '#1EB2C3',
          borderColor: 'divider',
          borderRight: '1px solid',
          borderRightColor: 'divider',
          textAlign: 'center',
          '&:last-child': {
            borderRight: 'none',
          },
        },
      },
    },
    [`& .${tableRowClasses.root}`]: {
      [`& .${tableCellClasses.root}`]: {
        fontSize: 14,
        p: 0,
        height: '72px',
        backgroundColor: '#FFFFFF',
        borderColor: 'divider',
        borderRight: '1px solid',
        borderRightColor: 'divider',
        px: '24px',
        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
  },
});

export default style;
