import { CircularProgress, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import CTAButton from '@/components/ui/CTAButton';
import TextButton from '@/components/ui/TextButton';

import style from './style';
import IconBadge from '@/components/ui/IconBadge';
import { FC } from 'react';

export interface StepControlProps {
  nextLabel: string;
  cancelLabel: string;
  savedLabel: string;
  errorLabel: string;
  formTouched?: boolean;
  hasError?: boolean;
  onCancel?: () => void;
  onNext?: () => void;
}

const StepControl: FC<StepControlProps> = ({
  nextLabel,
  savedLabel,
  cancelLabel,
  errorLabel,
  onNext,
  onCancel,
  formTouched,
  hasError,
}) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Stack sx={style.container}>
      {formTouched && (
        <Stack>
          {isSubmitting ? (
            <CircularProgress size={18} />
          ) : !hasError ? (
            <IconBadge label={savedLabel} icon="Saved" />
          ) : (
            <IconBadge label={errorLabel} icon="Error" />
          )}
        </Stack>
      )}
      <TextButton text={cancelLabel} onClick={onCancel} />
      <CTAButton
        variant="secondary"
        label={nextLabel}
        fixedWidth={186}
        onClick={onNext}
      />
    </Stack>
  );
};

export default StepControl;
