import { Stack } from '@mui/material';
import CalculatorPieSection from './CalculatorPieSection';
import CapitalDifferenceChart from './CapitalDifferenceChart';
import PensionChart from './PensionChart';
import CalculatorSmallTable from './CalculatorSmallTable';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import useCustomerStore from '@/store/customer';
import { futureValue } from '@/utils/math';
import { Customer } from '@/types';

const CalculatorView = () => {
  const { t } = useTranslation();
  const { customerId, fetchCustomer, customer, updateCustomer } =
    useCustomerStore();
  const [localCustomer, setLocalCustomer] = useState<Partial<Customer> | null>(
    null,
  );

  useEffect(() => {
    if (customerId) {
      fetchCustomer('customer', customerId);
    }
  }, [customerId, fetchCustomer]);

  useEffect(() => {
    if (customer) {
      setLocalCustomer(customer);
    }
  }, [customer]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalCustomer((prev) =>
      prev ? { ...prev, [name]: value === '' ? '' : parseFloat(value) } : prev,
    );
  };

  const handleBlurUpdate = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    if (!name || !customerId) return;

    try {
      await updateCustomer('customer', customerId, {
        [name]: value === '' ? null : parseFloat(value),
      });
    } catch (e) {
      console.error('Failed to update field:', e);
    }
  };

  const calculationData = useMemo(() => {
    if (!localCustomer) return null;

    const deposit = Number(localCustomer.bav_total_deposit || 0);
    const bavSubsidyPercent =
      Number(localCustomer.bav_subsidy_in_pension_plan_percent || 0) / 100;
    const bavInterestRatePercent =
      Number(localCustomer.bav_interest_rate || 3) / 100;
    const bavIsVLContributed = Number(
      localCustomer.bav_is_vl_contributed_to_pension_plan || 0,
    );

    const targetAge = localCustomer.savings_calculation?.target_age || 67;
    const currentAge = localCustomer.savings_calculation?.current_age || 49;
    const yearsBeforeRetirement = targetAge - currentAge;

    const arbeitnehmerAnteil =
      deposit / (1 + bavSubsidyPercent) - bavIsVLContributed;
    const arbeitgeberAnteil = deposit - arbeitnehmerAnteil + bavIsVLContributed;
    const deinNettoaufwand = arbeitnehmerAnteil * 0.47846661882;

    const sozialSteuerersparnis =
      deposit - arbeitgeberAnteil - deinNettoaufwand;

    const gesamtkapitalBAV = futureValue(
      bavInterestRatePercent,
      yearsBeforeRetirement,
      deposit * 12,
      localCustomer.bav_capital || 0,
    ).toNumber();

    const nettokapitalBAV = gesamtkapitalBAV - gesamtkapitalBAV * 0.4215;

    const gesamtkapitalETF = futureValue(
      0.07,
      yearsBeforeRetirement,
      deinNettoaufwand * 12,
      0,
    ).toNumber();

    const nettokapitalETF =
      gesamtkapitalETF -
      (((deinNettoaufwand * 12 * yearsBeforeRetirement - gesamtkapitalETF) *
        0.85) /
        2) *
        0.25;

    const kapitalverlust = gesamtkapitalBAV - nettokapitalBAV - nettokapitalETF;

    return {
      gesamtkapitalBAV,
      nettokapitalBAV,
      gesamtkapitalETF,
      nettokapitalETF,
      arbeitnehmerAnteil,
      arbeitgeberAnteil,
      kapitalverlust,
      sozialSteuerersparnis,
      deinNettoaufwand: deinNettoaufwand.toFixed(2),
    };
  }, [localCustomer]);

  if (!calculationData || !localCustomer) return null;

  return (
    <Stack flex={1} px={6} pb={6} gap={6} overflow="auto">
      <CalculatorPieSection
        data={{ ...localCustomer, ...calculationData }}
        handleInputChange={handleInputChange}
        handleBlurUpdate={handleBlurUpdate}
      />

      <PensionChart />
      <Section>
        <CalculatorSmallTable
          leftText={t('customer:calculator.effectiveMonthly')}
          rightText={calculationData.deinNettoaufwand}
        />
      </Section>
      <CapitalDifferenceChart
        gesamtkapitalBAV={calculationData.gesamtkapitalBAV}
        nettokapitalBAV={calculationData.nettokapitalBAV}
        gesamtkapitalETF={calculationData.gesamtkapitalETF}
        nettokapitalETF={calculationData.nettokapitalETF}
      />
      <Section>
        <CalculatorSmallTable
          leftText={t('customer:calculator.capitalLoss')}
          rightText={calculationData.kapitalverlust.toFixed(2)}
        />
      </Section>
    </Stack>
  );
};

export default CalculatorView;
