import { FC, FocusEventHandler, useCallback, useState } from 'react';
import TextInput, { TextInputProps } from '@/components/ui/TextInput';
import useFormField from '@/hooks/useFormField';
import { Stack, TableCell, TableCellProps } from '@mui/material';
import { compact, join } from 'lodash';

export interface TextFieldProps
  extends Omit<TextInputProps, 'value' | 'error' | 'onTextChange' | 'touched'> {
  tableCellProps?: TableCellProps;
  fieldName: string;
  inline?: boolean;
  valueEndAdornment?: string;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  addPadding?: boolean;
  value?: string | number;
  disabled?: boolean;
}

const CellTextField: FC<TextFieldProps> = ({
  fieldName,
  tableCellProps = {},
  valueEndAdornment,
  onBlur: onBlurCallback,
  handleInputChange,
  addPadding = true,
  ...props
}) => {
  const [editing, setEditing] = useState(false);

  const { value, error, setValue, onBlur, touched } = useFormField<string>(
    fieldName,
    '',
  );

  const handleTextChange = useCallback(
    (text: string) => {
      setValue(text);
      if (handleInputChange) {
        handleInputChange({
          target: { name: fieldName, value: text },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    },
    [setValue, handleInputChange, fieldName],
  );

  const handleClick = () => setEditing(true);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setEditing(false);
    onBlur(e);
    onBlurCallback?.(e);
  };

  return (
    <TableCell
      {...tableCellProps}
      onClick={props.disabled ? undefined : handleClick}
      sx={{
        backgroundColor: 'white',
        position: 'relative',
        cursor: props.disabled ? 'default' : 'pointer',
        borderColor: editing ? 'black !important' : '',
        borderTop: editing ? '2px solid black !important' : '',
        borderLeft: editing ? '2px solid black !important' : '',
        borderRight: editing ? '2px solid black !important' : '',
        borderBottom: editing ? '2px solid black !important' : '',
        '&:hover': props.disabled
          ? {}
          : {
              border: '2px solid black !important',
            },
      }}
    >
      {editing ? (
        <TextInput
          {...props}
          autoWidth
          error={error}
          value={String(props.value !== undefined ? props.value : value)}
          onTextChange={handleTextChange}
          onBlur={handleBlur}
          touched={touched}
          inTable
          autoFocus
          name={fieldName}
          disabled={props.disabled}

        />
      ) : (
        <Stack sx={addPadding ? { pl: '24px' } : {}}>
          {join(
            compact([
              props.value ?? value,
              !!(props.value ?? value) && valueEndAdornment,
            ]),
            '',
          )}
        </Stack>
      )}
    </TableCell>
  );
};

export default CellTextField;
