'use client';

import { Box, Typography, Stack } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

interface CalculatorPieChartProps {
  data: {
    arbeitnehmerAnteil: number;
    arbeitgeberAnteil: number;
    sozialSteuerersparnis: number;
  };
}

const renderCustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
}: any) => {
  if (percent < 0.05) return null;
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
      fontSize={16}
      fontWeight="bold"
    >
      {value.toFixed(2)} €
    </text>
  );
};

const CalculatorPieChart: React.FC<CalculatorPieChartProps> = ({ data }) => {
  const chartData = [
    { name: 'Nettoaufwand', value: data.arbeitnehmerAnteil, color: '#2EBFD0' },
    { name: 'AG-Anteil', value: data.arbeitgeberAnteil, color: '#00ABEA' },
    {
      name: 'Sozial-& Steuerersparnis',
      value: data.sozialSteuerersparnis,
      color: '#D33F33',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <PieChart width={400} height={400}>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomLabel}
          outerRadius={180}
          dataKey="value"
          nameKey="name"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => `${Number(value).toFixed(2)} €`} />
      </PieChart>

      <Stack direction="row" spacing={2} alignItems="center">
        {chartData.map((entry) => (
          <Stack
            key={entry.name}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                bgcolor: entry.color,
                borderRadius: '4px',
              }}
            />
            <Typography variant="body2">{entry.name}</Typography>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};

export default CalculatorPieChart;
