import { FC, FocusEventHandler, useMemo, useState } from 'react';

import TextInput, { TextInputProps } from '@/components/ui/TextInput';

import { TableCell, TableCellProps } from '@mui/material';
import { euro } from '@/utils/math';

export interface TextFieldProps extends TextInputProps {
  tableCellProps?: TableCellProps;
  valueType?: 'value' | 'currency' | 'percent';
  mapValue?: (value: string) => string;
}

const CellTextInput: FC<TextFieldProps> = ({
  tableCellProps = {},
  valueType,
  onBlur,
  value,
  mapValue,
  ...props
}) => {
  const [editing, setEditing] = useState(false);

  const handleClick = () => {
    setEditing(true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setEditing(false);
    onBlur?.(e);
  };

  const displayValue = useMemo(() => {
    switch (valueType) {
      case 'currency':
        return euro(value).format();
      case 'percent':
        return `${value.replace('.', ',')} %`;
      case 'value':
        return value;
      default:
        return value;
    }
  }, [value, valueType]);

  return (
    <TableCell
      {...tableCellProps}
      onClick={handleClick}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        borderColor: editing ? ' black !important' : '',
        borderTop: editing ? '2px solid black !important' : '',
        borderLeft: editing ? '2px solid black !important' : '',
        borderRight: editing ? '2px solid black !important' : '',
        borderBottom: editing ? '2px solid black !important' : '',
        '&:hover': {
          border: '2px solid black !important',
          borderRight: '2px solid black !important',
        },
      }}
    >
      {editing ? (
        <TextInput
          {...props}
          value={value}
          autoFocus
          autoWidth
          onBlur={handleBlur}
          inTable
        />
      ) : mapValue ? (
        mapValue(value)
      ) : (
        displayValue
      )}
    </TableCell>
  );
};

export default CellTextInput;
