import { Stack, Table } from '@mui/material';
import { FC } from 'react';
import { TableGeneratorProps } from './types';
import TableHead from './TableHead';
import TableRows from './TableRows';
import style from './style';

const TableGenerator: FC<TableGeneratorProps> = ({ head, values }) => {
  return (
    <Stack>
      <Table sx={style.table}>
        <TableHead values={head} />
        <TableRows values={values} />
      </Table>
    </Stack>
  );
};

export default TableGenerator;
