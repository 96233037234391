import i18n from 'i18next';
import {
  indexOf,
  isNull,
  isUndefined,
  join,
  mapValues,
  omitBy,
  some,
} from 'lodash';
import { SingleSelectItem } from '@/components/ui/SingleSelect';

export const getTranslationKey = (key: string, fallbackKey: string) => {
  return i18n.exists(key) ? key : fallbackKey;
};

export const showIf = <T extends Object, K extends keyof T, E>(
  form: T | null,
  key: K,
  values: T[K][],
  element: E,
) => {
  if (!form) {
    return null;
  }

  return some(values, (value) => form[key] === value) ? element : null;
};

export const getFormKey = (...keys: string[]) => {
  return join(keys, '.');
};

export const normalizeData = <T extends object, K extends keyof T>(
  object: T,
  omitKeys: K[],
): Partial<T> => {
  return omitBy(object, (value, key) => {
    return isUndefined(value) || isNull(value) || omitKeys.includes(key as K);
  });
};

export const getSelectItemsFromEnum = <T extends object>(
  enumValues: T,
): SingleSelectItem[] => {
  return Object.values(enumValues).map((value) => ({
    label: value,
    value,
  }));
};

export const mapToNumbers = <T extends object, K extends keyof T>(
  object: T,
  keys: K[],
  type: 'float' | 'int' = 'int',
) => {
  return mapValues(object, (value, key) => {
    if (indexOf(keys, key as K) === -1 || typeof value !== 'string') {
      return value;
    }

    return type === 'float' ? parseFloat(value) : parseInt(value);
  }) as unknown as Omit<T, K> & Record<K, number>;
};
