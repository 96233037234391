import { useField } from 'formik';
import { FocusEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useFormField = <T>(fieldName: string, fallbackValue: T) => {
  const { t } = useTranslation();

  const [
    { value: formikValue, onBlur },
    { touched, error: formikError },
    { setValue: formikSetValue, setTouched },
  ] = useField<T>(fieldName);

  const value = useMemo(() => {
    return formikValue;
  }, [formikValue]);

  const error = useMemo(() => {
    return touched && formikError ? t(`errors:form.${formikError}`) : undefined;
  }, [formikError, t, touched]);

  const setValue = useCallback(
    async (value: T) => {
      await formikSetValue(value);
    },
    [formikSetValue],
  );

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!touched) {
      setTouched(true).catch(console.error);
    }
    onBlur(e);
  };

  return {
    value: value ?? fallbackValue,
    error,
    onBlur: handleBlur,
    touched,
    setValue,
  };
};

export default useFormField;
