import { Stack, TableCell, TableRow } from '@mui/material';
import CellSelectField from '@/components/formControl/CellSelectField';
import { PropertyInsuranceList } from '@/constants/insurances';
import CellTextField from '@/components/formControl/CellTextField';
import IconButton from '@/components/ui/IconButton';
import { FC } from 'react';
import { getFormKey } from '@/utils/system';
import { Insurance } from '@/types';
import useFormField from '@/hooks/useFormField';

export interface PropertyInsuranceRowProps {
  formKey: string;
  index: number;
  onDelete: (i: number) => void;
  onUpdate?: (insurance: Partial<Insurance>) => void;
}

const PropertyInsuranceRow: FC<PropertyInsuranceRowProps> = ({
  onDelete,
  formKey,
  onUpdate,
}) => {
  const { value } = useFormField<Partial<Insurance>>(formKey, {});

  const handleUpdate = () => {
    onUpdate?.(value);
  };

  const handleDelete = () => {
    if (!value.id) return;
    onDelete(value.id);
  };

  return (
    <TableRow>
      <CellSelectField
        fieldName={getFormKey(formKey, 'type')}
        items={PropertyInsuranceList}
        onSelect={handleUpdate}
      />
      <CellTextField
        fieldName={getFormKey(formKey, 'provider')}
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'monthly_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'annual_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        fieldName={getFormKey(formKey, 'new_provider')}
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'new_monthly_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <CellTextField
        tableCellProps={{ width: 110 }}
        fieldName={getFormKey(formKey, 'new_annual_payment')}
        endAdornment="€"
        valueEndAdornment="€"
        onBlur={handleUpdate}
      />
      <TableCell width="50px">
        <Stack
          onClick={handleDelete}
          alignItems="center"
          justifyContent="center"
          p="6px"
          sx={{ cursor: 'pointer' }}
        >
          <IconButton icon="Delete" variant="primary" />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PropertyInsuranceRow;
