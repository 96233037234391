import { Box, Typography, useTheme } from '@mui/material';
import Section from '@/components/ui/Section';
import { useTranslation } from 'react-i18next';
import Image from '@/components/ui/Image';

const styles = {
  container: {
    padding: 2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '40px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  image: {
    width: 120,
    height: 'auto',
    cursor: 'pointer',
  },
  title: {
    fontSize: '24px',
    marginBottom: 2,
  },
  subtitle: {
    marginBottom: 2,
  },
};

const InvestmentSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Section>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box>
            <Typography
              variant="subtitle2"
              color={theme.palette.text.secondary}
            >
              {t('customer:lifeplan.investment_cost_warning')}
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              color={theme.palette.text.secondary}
              sx={{ mt: 0.5 }}
            >
              {t('customer:lifeplan.investment_real_estate_advice')}
            </Typography>
          </Box>
          <Box
            sx={styles.link}
            component="a"
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image image="Focus" sx={styles.image} />
          </Box>
        </Box>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          color={theme.palette.text.secondary}
          sx={styles.title}
        >
          {t('customer:lifeplan.investment_title')}
        </Typography>
        <Typography
          variant="subtitle2"
          color={theme.palette.text.secondary}
          sx={styles.subtitle}
        >
          {t('customer:lifeplan.investment_study_title')}
        </Typography>
        <Typography
          variant="subtitle2"
          color={theme.palette.text.secondary}
          sx={styles.subtitle}
        >
          {t('customer:lifeplan.investment_pension_collapse')}
        </Typography>
        <Typography variant="body2" color={theme.palette.text.primary}>
          {t('customer:lifeplan.investment_study_description')}
        </Typography>
      </Box>
    </Section>
  );
};

export default InvestmentSection;
