import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFormField from '@/hooks/useFormField';
import { Insurance } from '@/types';
import { euro } from '@/utils/math';

const SumRow = () => {
  const { t } = useTranslation();

  const { value } = useFormField<Insurance[]>('insurances', []);

  const totalMonth = value.reduce((acc, insurance) => {
    return acc.add(Number(insurance.monthly_payment) || 0);
  }, euro(0));

  const totalYear = value.reduce((acc, insurance) => {
    return acc.add(Number(insurance.annual_payment) || 0);
  }, euro(0));

  return (
    <TableRow>
      <TableCell className="total-cell" colSpan={2}>
        {t('customer:lifeInsurance.table.total')}
      </TableCell>
      <TableCell className="accent-cell">
        {totalMonth.add(totalYear.divide(12)).format()}
      </TableCell>
      <TableCell className="accent-cell">
        {totalYear.add(totalMonth.multiply(12)).format()}
      </TableCell>
      <TableCell colSpan={2} />
    </TableRow>
  );
};

export default SumRow;
