import { Stack, Table, TableCell, TableHead } from '@mui/material';

import style from './style';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik, FormikProps } from 'formik';
import { ProductsTable } from '@/types';
import { useRef } from 'react';
import { map, noop } from 'lodash';
import { getFormKey } from '@/utils/system';
import ProductRow from './ProductRow';
import AddProduct from './AddProduct';
import SumRowProduct from './SumRowProduct';

const ProductTable = () => {
  const { t } = useTranslation();

  const formRef = useRef<FormikProps<{ products: ProductsTable[] }>>(null);

  // @TODO : Add actual logic
  const deleteProduct = () => {
    console.log('deleted product');
  };
  const updateProduct = () => {
    console.log('updated product');
  };

  return (
    <Formik innerRef={formRef} initialValues={{ products: [] }} onSubmit={noop}>
      <Stack>
        <Table sx={style.table}>
          <TableHead>
            <TableCell sx={{ minWidth: 276 }}>
              {t('customer:concept.firstTab.table.product')}
            </TableCell>
            <TableCell width="20%">
              {t('customer:concept.firstTab.table.provider')}
            </TableCell>
            <TableCell width="20%">
              {t('customer:concept.firstTab.table.contribution')}
            </TableCell>
            <TableCell width="20%">
              {t('customer:concept.firstTab.table.funding')}
            </TableCell>
            <TableCell width="20%">
              {t('customer:concept.firstTab.table.downstream')}
            </TableCell>
          </TableHead>

          <FieldArray
            name="products"
            render={(helpers) => {
              return map(helpers.form.values.products, (_, i) => (
                <ProductRow
                  index={i}
                  formKey={getFormKey('products', i)}
                  onDelete={deleteProduct}
                  onUpdate={updateProduct}
                />
              ));
            }}
          />
          <SumRowProduct />
        </Table>
        <AddProduct />
      </Stack>
    </Formik>
  );
};

export default ProductTable;
