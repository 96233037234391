import Section from '@/components/ui/Section';
import TableGenerator from '@/components/ui/TableGenerator';
import { CostsTableHeader, CostsTableRows } from '@/constants/tables';

const CostsTable = () => {
  return (
    <Section>
      <TableGenerator head={CostsTableHeader} values={CostsTableRows} />
    </Section>
  );
};

export default CostsTable;
