import { createBrowserRouter, Navigate } from 'react-router-dom';

import RootView from '@/views/RootView';
import ProtectedRoute from '@/components/utility/ProtectedRoute';

// auth route views
import AuthView from '@/views/auth';
import SignInView from '@/views/auth/SignIn';
import ForgotPasswordView from '@/views/auth/ForgotPassword';
import ResetPasswordEmail from '@/views/auth/ResetPasswordEmail';
import ResetPasswordView from '@/views/auth/ResetPassword';
import ResetPasswordSuccess from '@/views/auth/ResetPasswordSuccess';

import HomeView from '@/views/home';

// admin views
import AdminView from 'src/views/home/admin';

// customer views
import CustomersView from '@/views/home/customer';
import CustomerDetailsView from '@/views/home/customer/CustomerDetailsView';
import PermissionAwareNavigate from '@/components/utility/PermissionAwareNavigate';
import HousePayment from '@/views/home/customer/plan/HousePayment';
import CustomerView from '@/views/home/customer/CustomerView';
import LifePlanView from '@/views/home/customer/plan';
import PannerView from '@/views/home/customer/planner';
import BSCView from '@/views/home/customer/planner/BSC';
import LifeInsuranceView from '@/views/home/customer/planner/LifeInsurance';
import FinanceView from '@/views/home/customer/finance';
import FinanceInfoView from '@/views/home/customer/finance/FinanceInfo';
import FundingView from '@/views/home/customer/finance/Funding';
import LifeRoadPage from '@/views/home/customer/plan/LifeRoadPage';
import SavingsView from '@/views/home/customer/planner/Savings';
import DiagramView from '@/views/home/customer/plan/Diagram';
import { Routes } from '@/constants/routes';
import { getPath } from '@/utils';
import KLVView from '@/views/home/customer/planner/KLV';
import ChildrenDiagramView from '@/views/home/customer/plan/ChildrenDiagram';
import LifeRoadAnalyticsPage from '@/views/home/customer/plan/LifeRoadAnalyticsPage';
import CalculatorView from '@/views/home/customer/planner/Calculator';
import ConceptView from '@/views/home/customer/concept';
import HouseholdView from '@/views/home/customer/household';
import HouseholdCalculator from '@/views/home/customer/household/HouseholdCalculator';
import ConceptTable from '@/views/home/customer/concept/ConceptTable';
import EstgPage from '@/views/home/customer/plan/EstgPage';


const CustomerRoutes = [
  {
    index: true,
    element: <Navigate to={Routes.CustomerDetails} replace />,
  },
  {
    path: Routes.CustomerDetails,
    element: <CustomerDetailsView />,
  },
  {
    path: Routes.LifePlan,
    element: <LifePlanView />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.Diagram} replace />,
      },
      {
        path: Routes.Diagram,
        element: <DiagramView />,
      },
      {
        path: Routes.Children,
        element: <ChildrenDiagramView />,
      },
      {
        path: Routes.HousePayment,
        element: <HousePayment />,
      },
      {
        path: Routes.LifeRoad,
        element: <LifeRoadPage />,
      },
      {
        path: Routes.LifeRoadAnalytics,
        element: <LifeRoadAnalyticsPage />,
      },
      {
        path: Routes.Estg,
        element: <EstgPage />,
      },
    ],
  },
  {
    path: Routes.Planner,
    element: <PannerView />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.LifeInsurance} replace />,
      },
      {
        path: Routes.LifeInsurance,
        element: <LifeInsuranceView />,
      },
      {
        path: Routes.BSC,
        element: <BSCView />,
      },
      {
        path: Routes.KLV,
        element: <KLVView />,
      },
      {
        path: Routes.Savings,
        element: <SavingsView />,
      },
      {
        path: Routes.Calculator,
        element: <CalculatorView />,
      },
    ],
  },
  {
    path: Routes.Finance,
    element: <FinanceView />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.Funding} replace />,
      },
      {
        path: Routes.Funding,
        element: <FundingView />,
      },
      {
        path: Routes.FinanceInfo,
        element: <FinanceInfoView />,
      },
    ],
  },
  {
    path: Routes.Household,
    element: <HouseholdView />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.HouseholdCalculator} replace />,
      },
      {
        path: Routes.HouseholdCalculator,
        element: <HouseholdCalculator />,
      },
      {
        path: Routes.HouseholdCompare,
        element: '',
      },
    ],
  },
  {
    path: Routes.Concept,
    element: <ConceptView />,
    children: [
      {
        index: true,
        element: <Navigate to={Routes.Test} replace />,
      },
      {
        path: Routes.Test,
        element: <ConceptTable />,
      },
    ],
  },
];

const router = createBrowserRouter([
  {
    path: Routes.Root,
    element: <RootView />,
    children: [
      {
        index: true,
        element: <Navigate replace to={Routes.Home} />,
      },
      {
        path: Routes.Home,
        element: (
          <ProtectedRoute>
            <HomeView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: (
              <PermissionAwareNavigate
                to={[
                  [['users', 'customers'], Routes.Admin],
                  [['customers'], Routes.Customers],
                ]}
                replace
              />
            ),
          },
          {
            path: Routes.Admin,
            element: <AdminView />,
          },
          {
            path: Routes.Customers,
            element: <CustomersView />,
          },
        ],
      },
      {
        path: getPath(Routes.Home, Routes.Customers, Routes.NewCustomer),
        element: (
          <ProtectedRoute>
            <CustomerView />
          </ProtectedRoute>
        ),
        children: CustomerRoutes,
      },
      {
        path: getPath(Routes.Home, Routes.Customers, Routes.Customer),
        element: (
          <ProtectedRoute>
            <CustomerView />
          </ProtectedRoute>
        ),
        children: CustomerRoutes,
      },
      {
        path: Routes.Auth,
        element: (
          <ProtectedRoute authFlow>
            <AuthView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to={Routes.SignIn} />,
          },
          {
            path: Routes.SignIn,
            element: <SignInView />,
          },
          {
            path: Routes.ForgotPassword,
            element: <ForgotPasswordView />,
          },
          {
            path: `${Routes.PasswordReset}/:token'`,
            element: <ResetPasswordView />,
          },
        ],
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordEmail),
        element: <ResetPasswordEmail />,
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordSuccess),
        element: <ResetPasswordSuccess />,
      },
    ],
  },
]);

export default router;
