import { Box } from '@mui/material';

interface CapitalBuildRowProps {
  label: string;
  value: number;
}

// Function to format numbers as "1.234.567,89"
const formatNumber = (num: number): string => {
  return num.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '100%',
  },
  leftSection: {
    backgroundColor: '#00ABEA',
    color: 'white',
    padding: '12px 16px',
    width: '50%',
    fontWeight: 'bold',
  },
  middleSection: {
    padding: '12px 16px',
    color: '#475467',
    fontWeight: 'bold',
    width: '25%',
    textAlign: 'left',
    borderLeft: '1px solid #E0E0E0',
    flex: 1,
  },
  rightSection: {
    padding: '12px 16px',
    fontWeight: 'bold',
    width: '25%',
    textAlign: 'left',
    borderLeft: '1px solid #E0E0E0',
  },
};

const CapitalBuildRow: React.FC<CapitalBuildRowProps> = ({ label, value }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.leftSection}>{label}</Box>

      <Box sx={styles.middleSection}>{formatNumber(value)} €</Box>

      <Box sx={styles.rightSection}>{formatNumber(value / 20)} €</Box>
    </Box>
  );
};

export default CapitalBuildRow;
