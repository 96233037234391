import { FC, useEffect, useState } from 'react';
import { Box, Card, Typography, Button, Stack } from '@mui/material';
import Section from '@/components/ui/Section';
import { CardData } from './LifeRoadPage';
import Image from '@/assets/images';

interface RoadmapProps {
  getCardsData: (cardsData: CardData[]) => void;
}

// TODO: Replace with real data source when available
const initialCards: CardData[] = [
  { name: 'Zukunftsplan für Ihre Kinder', isActive: false, isPositive: false },
  {
    name: 'Instandhaltungs-rücklage für Ihre Immobilie',
    isActive: false,
    isPositive: false,
  },
  { name: 'Existenzsicherung im Alter', isActive: false, isPositive: false },
  { name: 'Vermögensaufbau', isActive: false, isPositive: false },
  { name: 'Erwerb einer Immobilie', isActive: false, isPositive: false },
  {
    name: 'Früher Schuldenfrei (Immobilien-darlehen)',
    isActive: false,
    isPositive: false,
  },
];

const styles = {
  roadmapBox: {
    width: '100%',
    aspectRatio: '16 / 9',
    margin: '0 auto',
    borderRadius: 2,
    overflow: 'hidden',
    backgroundImage: `url(${Image.RoadImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    py: 4,
  },
  roadmapStack: {
    gap: { xs: '20px', sm: '5vw', md: 'clamp(20px, 15vw, 66px)' },
  },
  card: {
    width: '164px',
    height: '105px',
    p: 2,
    backdropFilter: 'blur(10px)',
    borderRadius: 2,
    textAlign: 'center',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  button: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    borderRadius: '50%',
  },
};

const Roadmap: FC<RoadmapProps> = ({ getCardsData }) => {
  const [cards, setCards] = useState<CardData[]>(initialCards);

  const toggleCard = (index: number, isPositive: boolean) => {
    setCards((prev) =>
      prev.map((card, i) =>
        i === index ? { ...card, isActive: true, isPositive } : card,
      ),
    );
  };

  const resetCard = (index: number) => {
    setCards((prev) =>
      prev.map((card, i) =>
        i === index ? { ...card, isActive: false, isPositive: false } : card,
      ),
    );
  };

  useEffect(() => {
    getCardsData(cards);
  }, [cards, getCardsData]);

  return (
    <Stack pb={6} gap={6}>
      <Section>
        <Box sx={styles.roadmapBox}>
          <Stack sx={styles.roadmapStack}>
            {[0, 3].map((rowStart) => (
              <Stack
                key={rowStart}
                direction="row"
                gap={{
                  xs: '20px',
                  sm: '30px',
                  md: '50px',
                  lg: '100px',
                  xl: '140px',
                  xxl: '165px',
                }}
                justifyContent="center"
                sx={{
                  pl:
                    rowStart === 3
                      ? {
                          xs: '20px',
                          sm: '5vw',
                          md: 0,
                          lg: 'clamp(20px, 15vw, 100px)',
                          xl: 'clamp(20px, 12vw, 140px)',
                          xxl: 'clamp(20px, 10vw, 165px)',
                        }
                      : 0,
                }}
              >
                {cards.slice(rowStart, rowStart + 3).map((card, index) => {
                  const i = rowStart + index;
                  return (
                    <Stack key={i} spacing={1} alignItems="center">
                      <Stack direction="row" gap={1}>
                        {/* TODO cards will change */}
                        <Button
                          onClick={() => toggleCard(i, false)}
                          sx={{
                            ...styles.button,
                            backgroundColor:
                              card.isActive && !card.isPositive
                                ? '#D33F33'
                                : 'rgba(255, 255, 255, 0.4)',
                          }}
                        />
                        <Button
                          onClick={() => toggleCard(i, true)}
                          sx={{
                            ...styles.button,
                            backgroundColor:
                              card.isActive && card.isPositive
                                ? '#56D973'
                                : 'rgba(255, 255, 255, 0.4)',
                          }}
                        />
                      </Stack>
                      <Card
                        onClick={() => resetCard(i)}
                        sx={{
                          ...styles.card,
                          backgroundColor: card.isActive
                            ? 'white'
                            : 'rgba(255, 255, 255, 0.4)',
                          color: card.isActive
                            ? card.isPositive
                              ? '#56D973'
                              : '#D33F33'
                            : 'white',
                          boxShadow: card.isActive
                            ? `0 0 15px ${card.isPositive ? '#56D973' : '#D33F33'}`
                            : '0 4px 12px rgba(0,0,0,0.1)',
                          transition: 'box-shadow 0.3s ease-in-out',
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight="semibold"
                          sx={{ fontSize: '14px' }}
                        >
                          {card.name}
                        </Typography>
                      </Card>
                    </Stack>
                  );
                })}
              </Stack>
            ))}
          </Stack>
        </Box>
      </Section>
    </Stack>
  );
};

export default Roadmap;
