import { Stack } from '@mui/system';
import Roadmap from './Roadmap';
import { FC, useState } from 'react';
import RentFree from './LifeRoadSections/RentFree';
import RoadSection from './LifeRoadSections/RoadSection';
import KinderSection from './LifeRoadSections/KinderSection';
import InterestRateSection from './LifeRoadSections/InterestRateSection';
import FocusEmpty from './LifeRoadSections/FocusEmpty';
import InvestmentSection from './LifeRoadSections/InvestmentSection';
import AnalyticsSection from './LifeRoadSections/AnalyticsSection';
import LifeRoadTables from './LifeRoadTables';
import PositiveCurve from './LifeRoadSections/PositiveCurve';
import CostsTable from './LifeRoadSections/CostsTable';
import NegativeCurve from './LifeRoadSections/NegativeCurve';

export interface CardData {
  name: string;
  isActive: boolean;
  isPositive: boolean;
}

const LifeRoadPage: FC = () => {
  const [cards, setCards] = useState<Record<string, CardData>>({});

  const getCardsData = (cardsData: CardData[]) => {
    const cardsObj = cardsData.reduce(
      (acc, card, index) => {
        acc[`card${index}`] = card;
        return acc;
      },
      {} as Record<string, CardData>,
    );

    setCards((prevCards) => {
      const newCards = { ...prevCards, ...cardsObj };
      if (JSON.stringify(newCards) !== JSON.stringify(prevCards)) {
        return newCards;
      }
      return prevCards;
    });
  };

  return (
    <Stack px={6} pb={6} gap={6} overflow="auto">
      <Roadmap getCardsData={getCardsData} />
      <LifeRoadTables cards={Object.values(cards)} />
      {Object.entries(cards).map(
        ([key, card]) =>
          card?.isPositive && (
            <div key={key}>
              {key === 'card0' && <RoadSection />}
              {key === 'card1' && <KinderSection />}
              {key === 'card2' && <InterestRateSection />}
              {key === 'card3' && (
                <Stack gap={6}>
                  <RentFree />
                  <PositiveCurve />
                  <CostsTable />
                </Stack>
              )}
              {key === 'card4' && (
                <Stack gap={6}>
                  <FocusEmpty />
                  <NegativeCurve />
                </Stack>
              )}
              {key === 'card5' && (
                <Stack gap={6}>
                  <InvestmentSection />
                  <AnalyticsSection />
                </Stack>
              )}
            </div>
          ),
      )}
    </Stack>
  );
};

export default LifeRoadPage;
